import { createAsyncThunk } from '@reduxjs/toolkit';

import { serializeFiltersToString } from 'helpers/filters';
import {
  getQuotes,
  getQuotesCount as getQuotesCountRemote,
  updateQuote as updateQuoteRemote,
} from 'services/quotes';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/quotes/actions';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.quotes.data.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.quotes.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Quote;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchQuotes = createAsyncThunk(
  'quotes-get',
  async (params = {}, { dispatch, getState }) => {
    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getQuotes(payload)
        .then((json) => Promise.resolve({ ...json, params: { ...stateParams, ...params } }))
        .catch((err) => Promise.reject(err));
    });
  }
);

export const getQuotesCount = createAsyncThunk(
  'quotes-count',
  async (params = {}, { getState }) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };
    return getQuotesCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  }
);

export const updateQuote = createAsyncThunk('quote-update', async (data, { dispatch }) => {
  try {
    const res = await updateQuoteRemote(data.id, data.payload);
    return res.quote;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});
