import { createSelector } from '@reduxjs/toolkit';

const DISPLAY_TYPES = {
  CREATE: 'create',
  LIST: 'list',
  DETAILS: 'details',
};

// TODO: Move to helper
function mapFieldsObjectToArray(fields) {
  return Object.keys(fields).map((key) => ({
    name: key,
    ...fields[key],
  }));
}

// TODO: Move to helper
function filterFieldsByType(fields, displayType) {
  return fields.filter((field) => field[displayType]);
}

function excludeFirstAndLastNameFromContactListFields(fields) {
  return fields.filter((f) => !['first_name', 'last_name'].includes(f.name));
}

function filterFieldsForUserCreate(fields) {
  const requiredFields = ['first_name', 'last_name', 'email', 'group_id'];

  return fields
    .filter((f) =>
      [
        'first_name',
        'last_name',
        'email',
        'phone',
        'title',
        'role',
        'email_visibility',
        'group_id',
      ].includes(f.name)
    )
    .map((f) => ({
      ...f,
      label: requiredFields.includes(f.name) ? `${f.label} *` : f.label,
    }));
}

function excludeDescriptionFromReminderDetailFields(fields) {
  return fields.filter((f) => !['description'].includes(f.name));
}

// TODO: Remove
export const fieldsSelector = (state) => state.displayFields.fields;

export const selectDisplayFields = (state) => state.displayFields.fields;

export const selectSelectedEntity = (state) => state.displayFields.selectedEntity;

export const selectSelectedEntityDisplayFields = createSelector(
  [selectDisplayFields, selectSelectedEntity],
  (displayFields, selectedEntity) => displayFields[selectedEntity]
);

export function opportunityFieldsSelector(state) {
  const fields = state.displayFields.fields.Opportunity;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getOpportunityDisplayFieldsForCreate = createSelector(
  [opportunityFieldsSelector],
  (opportunityFields) => filterFieldsByType(opportunityFields, DISPLAY_TYPES.CREATE)
);

export const getOpportunityDisplayFieldsForList = createSelector(
  [opportunityFieldsSelector],
  (opportunityFields) => filterFieldsByType(opportunityFields, DISPLAY_TYPES.LIST)
);

export const getOpportunityDisplayFieldsForDetails = createSelector(
  [opportunityFieldsSelector],
  (opportunityFields) => filterFieldsByType(opportunityFields, DISPLAY_TYPES.DETAILS)
);

export function contactFieldsSelector(state) {
  const fields = state.displayFields.fields.Contact;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export function interactionFieldsSelector(state) {
  const fields = state.displayFields.fields.Interaction;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getContactDisplayFieldsForCreate = createSelector(
  [contactFieldsSelector],
  (contactFields) => filterFieldsByType(contactFields, DISPLAY_TYPES.CREATE)
);

export const getContactDisplayFieldsForList = createSelector(
  [contactFieldsSelector],
  (contactFields) => {
    const fields = filterFieldsByType(contactFields, DISPLAY_TYPES.LIST);
    return [
      {
        name: 'name',
        label: 'Name',
        type: 'String',
        list: true,
        edit: true,
        editable: true,
        order: 1,
        sortable: true,
      },
      ...excludeFirstAndLastNameFromContactListFields(fields),
    ];
  }
);

export const getContactDisplayFieldsForDetails = createSelector(
  [contactFieldsSelector],
  (contactFields) => filterFieldsByType(contactFields, DISPLAY_TYPES.DETAILS)
);

export function accountFieldsSelector(state) {
  const fields = state.displayFields.fields.Account;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getAccountDisplayFieldsForCreate = createSelector(
  [accountFieldsSelector],
  (accountFields) => filterFieldsByType(accountFields, DISPLAY_TYPES.CREATE)
);

export const getAccountDisplayFieldsForList = createSelector(
  [accountFieldsSelector],
  (accountFields) => filterFieldsByType(accountFields, DISPLAY_TYPES.LIST)
);

export const getAccountDisplayFieldsForDetails = createSelector(
  [accountFieldsSelector],
  (accountFields) => filterFieldsByType(accountFields, DISPLAY_TYPES.DETAILS)
);

export const getInteractionDisplayFieldsForDetails = createSelector(
  [interactionFieldsSelector],
  (interactionFields) => filterFieldsByType(interactionFields, DISPLAY_TYPES.DETAILS)
);

export function ticketsFieldsSelector(state) {
  const fields = state.displayFields.fields.Ticket;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getTicketsDisplayFieldsForCreate = createSelector(
  [ticketsFieldsSelector],
  (ticketsFields) => {
    const fields = filterFieldsByType(ticketsFields, DISPLAY_TYPES.CREATE).map((df) => df);
    return [
      {
        label: 'Description',
        name: 'description',
        type: 'description',
        create: true,
        custom: false,
        details: true,
        edit: true,
        readonly: false,
        removable: false,
        sortable: false,
        list: true,
        order: 99999,
      },
      ...fields,
    ].sort((a, b) => a.order - b.order);
  }
);

export const getTicketsDisplayFieldsForList = createSelector(
  [ticketsFieldsSelector],
  (ticketsFields) => filterFieldsByType(ticketsFields, DISPLAY_TYPES.LIST)
);

export const getTicketsDisplayFieldsForDetails = createSelector(
  [ticketsFieldsSelector],
  (ticketsFields) => filterFieldsByType(ticketsFields, DISPLAY_TYPES.DETAILS)
);

export function remindersFieldsSelector(state) {
  const fields = state.displayFields.fields.Reminder;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getRemindersDisplayFieldsForDetails = createSelector(
  [remindersFieldsSelector],
  (remindersFields) => {
    const fields = filterFieldsByType(remindersFields, DISPLAY_TYPES.DETAILS);
    return excludeDescriptionFromReminderDetailFields(fields);
  }
);

export const getRemindersDisplayFieldsForList = createSelector(
  [remindersFieldsSelector],
  (remindersFields) => filterFieldsByType(remindersFields, DISPLAY_TYPES.LIST)
);

export const getRemindersDisplayFieldsForCreate = createSelector(
  [remindersFieldsSelector],
  (remindersFields) => filterFieldsByType(remindersFields, DISPLAY_TYPES.CREATE)
);

export function campaignsFieldsSelector(state) {
  const fields = state.displayFields.fields.List;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getCampaignsDisplayFieldsForCreate = createSelector(
  [campaignsFieldsSelector],
  (campaignsFields) => filterFieldsByType(campaignsFields, DISPLAY_TYPES.CREATE)
);

export const getCampaignsDisplayFieldsForList = createSelector(
  [campaignsFieldsSelector],
  (campaignsFields) => filterFieldsByType(campaignsFields, DISPLAY_TYPES.LIST)
);

export const getCampaignsDisplayFieldsForDetails = createSelector(
  [campaignsFieldsSelector],
  (campaignsFields) => filterFieldsByType(campaignsFields, DISPLAY_TYPES.DETAILS)
);

export function userFieldsSelector(state) {
  const fields = state.displayFields.fields.User;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getUserDisplayFieldsForCreate = createSelector([userFieldsSelector], (userFields) => {
  const fields = filterFieldsByType(userFields, DISPLAY_TYPES.CREATE).map((df) => {
    if (df.name !== 'license_type') return df;
    return { ...df, readonly: false };
  });

  return [
    {
      label: 'Manager',
      name: 'parent_id',
      type: 'List',
      create: true,
      custom: false,
      details: true,
      edit: true,
      readonly: false,
      removable: false,
      sortable: false,
      list: true,
      order: 10,
    },
    ...filterFieldsForUserCreate(fields),
  ].sort((a, b) => a.order - b.order);
});

export const getUserDisplayFieldsForList = createSelector([userFieldsSelector], (userFields) =>
  filterFieldsByType(userFields, DISPLAY_TYPES.LIST)
);

export const getUserDisplayFieldsForDetails = createSelector([userFieldsSelector], (userFields) =>
  [
    {
      label: 'Row Height',
      name: 'row_height',
      type: 'List',
      create: true,
      custom: false,
      details: true,
      edit: true,
      readonly: false,
      removable: false,
      sortable: false,
      list: true,
      order: 15,
    },
    ...filterFieldsByType(userFields, DISPLAY_TYPES.DETAILS),
  ].sort((a, b) => a.order - b.order)
);

export function productFieldsSelector(state) {
  const fields = state.displayFields.fields.Product;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getProductDisplayFieldsForCreate = createSelector(
  [productFieldsSelector],
  (productFields) => filterFieldsByType(productFields, DISPLAY_TYPES.CREATE)
);

export const getProductDisplayFieldsForList = createSelector(
  [productFieldsSelector],
  (productFields) => filterFieldsByType(productFields, DISPLAY_TYPES.LIST)
);

export const getProductDisplayFieldsForDetails = createSelector(
  [productFieldsSelector],
  (productFields) => filterFieldsByType(productFields, DISPLAY_TYPES.DETAILS)
);

export function quotesFieldsSelector(state) {
  const fields = state.displayFields.fields.Quote;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getQuoteDisplayFieldsForCreate = createSelector(
  [quotesFieldsSelector],
  (quoteFields) => filterFieldsByType(quoteFields, DISPLAY_TYPES.CREATE)
);

export const getQuoteDisplayFieldsForList = createSelector([quotesFieldsSelector], (quoteFields) =>
  filterFieldsByType(quoteFields, DISPLAY_TYPES.LIST)
);

export const getQuoteDisplayFieldsForDetails = createSelector(
  [quotesFieldsSelector],
  (quoteFields) => filterFieldsByType(quoteFields, DISPLAY_TYPES.DETAILS)
);

export function ordersFieldsSelector(state) {
  const fields = state.displayFields.fields.SalesOrder;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getOrdersDisplayFieldsForCreate = createSelector(
  [ordersFieldsSelector],
  (orderFields) => filterFieldsByType(orderFields, DISPLAY_TYPES.CREATE)
);

export const getOrdersDisplayFieldsForList = createSelector([ordersFieldsSelector], (orderFields) =>
  filterFieldsByType(orderFields, DISPLAY_TYPES.LIST)
);

export const getOrdersDisplayFieldsForDetails = createSelector(
  [ordersFieldsSelector],
  (orderFields) => filterFieldsByType(orderFields, DISPLAY_TYPES.DETAILS)
);

export function ordersItemFieldsSelector(state) {
  const fields = state.displayFields.fields.SalesOrderItem;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export const getOrderItemDisplayFieldsForCreate = createSelector(
  [ordersItemFieldsSelector],
  (orderFields) => filterFieldsByType(orderFields, DISPLAY_TYPES.CREATE)
);

export const getOrderItemDisplayFieldsForList = createSelector(
  [ordersItemFieldsSelector],
  (orderFields) => filterFieldsByType(orderFields, DISPLAY_TYPES.LIST)
);

export const getOrderItemDisplayFieldsForDetails = createSelector(
  [ordersItemFieldsSelector],
  (orderFields) => filterFieldsByType(orderFields, DISPLAY_TYPES.DETAILS)
);

export function ordersSummaryFieldsSelector(state) {
  const fields = state.displayFields.fields.SalesOrderSummary;
  return fields ? mapFieldsObjectToArray(fields) : [];
}
export function quoteSummaryFieldsSelector(state) {
  const fields = state.displayFields.fields.QuoteSummary;
  return fields ? mapFieldsObjectToArray(fields) : [];
}

export function ordersSummaryFieldsObjectSelector(state) {
  const fields = state.displayFields.fields.SalesOrderSummary;
  return fields ?? {};
}

export const getOrdersSummaryDisplayFieldsForDetails = createSelector(
  [ordersSummaryFieldsSelector],
  (orderSummaryFields) => filterFieldsByType(orderSummaryFields, DISPLAY_TYPES.DETAILS)
);

export const getQuoteSummaryDisplayFieldsForDetails = createSelector(
  [quoteSummaryFieldsSelector],
  (quoteSummaryFields) => filterFieldsByType(quoteSummaryFields, DISPLAY_TYPES.DETAILS)
);
