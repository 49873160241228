import Day from 'dayjs';
import get from 'lodash/get';
import pick from 'lodash/pick';

function blank() {
  return {
    id: null,
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    username: '',
    status: '',
    role: '',
    isAdmin: '',
    isManager: '',
    managerFullName: '',
    parentId: null,
    lastSeenAt: null,
  };
}

export const licensesTypes = {
  full_license: 'Full License',
  system_license: 'System License',
  read_only_license: 'Read Only License',
  email_sync_license: 'Email Sync License',
  phone_only_license: 'Phone License',
};

export function parse(data = {}) {
  if (!data) {
    return null;
  }

  return {
    ...blank(),
    id: data.id,
    fullName: data.full_name,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    username: data.username,
    status: data.status,
    role: data.role,
    isAdmin: data.is_admin,
    isManager: data.is_manager,
    managerFullName: data.manager_full_name,
    parentId: data.parent_id,
    lastSeenAt: data.last_seen_at && new Day(data.last_seen_at),
    custom: data.custom,
    monthlyQuota: data.monthlyQuota,
    quarterlyQuota: data.quarterlyQuota,
    yearlyQuota: data.yearlyQuota,
    twilioProfile: data.twilio_profile ? { number: data.twilio_profile.number } : null,
    spiroSubscription: data.spiro_subscription
      ? {
          id: data.spiro_subscription.id,
          customerId: data.spiro_subscription.customer_id,
          friendlyName: data.spiro_subscription.friendly_name,
          licenses: data.spiro_subscription.licenses,
          organizationId: data.spiro_subscription.organization_id,
          productId: data.spiro_subscription.product_id,
          subscriptionId: data.spiro_subscription.subscription_id,
          userId: data.spiro_subscription.user_id,
          expiryDate: new Day(data.spiro_subscription.expiry_date).format('MMMM D, YYYY'),
        }
      : null,
    groupId: data.group_id,
    emailConnected: data.email_connected,
    accountLocked: data.account_locked || false,
    settings: data.settings,
    emailVisibility: data.email_visibility,
    countryCode: data.country_code || 'US',
    emailClient: get(data, 'settings.email_client') || '',
    title: data.title || '',
    phone: data.phone || '',
    currency: data.currency || 'USD',
    voipPopupDisabled: data.voip_popup_disabled || false,
    licenseType: licensesTypes[data.license_type] ?? '-',
    rawLicenseType: data.license_type,
    externalID: data.external_id || '',
    loginAsDisabled: data.login_as_disabled || false,
    notetakerEnabled: data.notetaker_enabled || false,
    phoneEnabled: data.phone_enabled || false,
    deactivatedAt: data.deactivated_at || null,
    reassignedTo: data.reassigned_to_name || null,
  };
}

export function parseAll(data = []) {
  return data.map((item) => parse(item));
}

export function splitFullName(fullName = '') {
  const segments = fullName.trim().split(' ');
  return {
    firstName: segments[0],
    lastName: segments.length > 1 ? segments[segments.length - 1] : '',
  };
}

export function mapUsersForSelectList(users) {
  return users.map((user) => ({
    label: user.fullName,
    value: user.id,
  }));
}

export function getRoleDisplayName(role) {
  switch (role) {
    case 'normal':
      return 'Sales Rep';
    case 'basic':
      return 'Sales Rep (Limited)';
    case 'manager':
      return 'Manager';
    case 'basic_manager':
      return 'Manager (Limited)';
    case 'supermanager':
      return 'Executive (All Data)';
    case 'partner':
      return 'Partner';
    default:
      return '';
  }
}

export function serialize(user, keys) {
  const s = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    phone: user.phone,
    username: user.username,
    password: user.password,
    password_confirmation: user.passwordConfirmation,
    title: user.title,
    role: user.role,
    parent_id: user.parentId,
    group_id: user.groupId,
    custom: user.custom,
    monthly_quota: user.monthlyQuota,
    quarterly_quota: user.quarterlyQuota,
    yearly_quota: user.yearlyQuota,
    currency: user.currency,
    email_client: user.emailClient,
    country_code: user.countryCode,
    is_admin: user.isAdmin,
    email_visibility: user.emailVisibility,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export function getUserCurrency(user) {
  switch (user.currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'CAD':
      return '$';
    case 'AUD':
      return '$';
    case 'NZD':
      return '$';
    case 'ZAR':
      return 'R';
    case 'GBP':
      return '£';
    default:
      return '';
  }
}

export default {
  parse,
  parseAll,
  serialize,
};
