import { createSlice } from '@reduxjs/toolkit';

import {
  createEngineRulesFolder,
  deleteEngineRulesFolder,
  fetchEngineRulesFolders,
  handleCreateEngineRule,
  updateEngineRulesFolder,
} from './actions';

const initialState = {
  fetching: false,
  data: [],
  params: {
    sort: 'name',
    order: 'asc',
    q: undefined,
    query: '',
  },
  createInProgress: false,
  expandedFolders: [],
  initialLoading: false,
};

const sortByName = (folders) => [...folders].sort((a, b) => a.name.localeCompare(b.name));

export const engineRulesSlice = createSlice({
  name: 'engine-rules',
  initialState,
  reducers: {
    setEngineRules: (state, { payload }) => ({ ...state, data: payload }),
    setExpandedFolders: (state, { payload }) => ({
      ...state,
      expandedFolders: state.expandedFolders.includes(payload)
        ? state.expandedFolders.filter((id) => id !== payload)
        : [...state.expandedFolders, payload],
    }),
    setIntialLoading: (state, { payload }) => ({
      ...state,
      initialLoading: payload,
    }),
  },
  extraReducers: {
    [handleCreateEngineRule.pending]: (state) => ({ ...state, createInProgress: true }),
    [handleCreateEngineRule.fulfilled]: (state) => ({
      ...state,
      createInProgress: false,
    }),
    [handleCreateEngineRule.rejected]: (state) => ({ ...state, createInProgress: false }),
    [fetchEngineRulesFolders.pending]: (state) => ({ ...state, fetching: true }),
    [fetchEngineRulesFolders.fulfilled]: (state, { payload }) => {
      function getFolderIdsWithRules() {
        if (payload.params.q !== '' || payload.params.query !== '') {
          const foldersWithRules = payload.assistant_rule_folders
            .filter((folder) => folder.assistant_rules.length > 0)
            .map((folder) => folder.id);

          return foldersWithRules;
        }

        return state.expandedFolders;
      }

      return {
        ...state,
        data: payload.assistant_rule_folders,
        initialLoading: false,
        fetching: false,
        params: payload.params,
        expandedFolders: getFolderIdsWithRules(),
      };
    },
    [fetchEngineRulesFolders.rejected]: (state) => ({ ...state, fetching: false }),
    [createEngineRulesFolder.fulfilled]: (state, { payload }) => {
      const updatedFolders = [...state.data, payload.assistant_rule_folder];
      return {
        ...state,
        data: sortByName(updatedFolders),
      };
    },
    [updateEngineRulesFolder.fulfilled]: (state, { payload }) => {
      const updatedFolders = state.data.map((folder) =>
        folder.id === payload.assistant_rule_folder.id ? payload.assistant_rule_folder : folder
      );
      return {
        ...state,
        data: sortByName(updatedFolders),
      };
    },
    [deleteEngineRulesFolder.fulfilled]: (state, { payload }) => {
      const updatedFolders = state.data.filter((folder) => folder.id !== payload.id);

      return {
        ...state,
        data: sortByName(updatedFolders),
      };
    },
  },
});

export const { setEngineRules, setExpandedFolders, setIntialLoading } = engineRulesSlice.actions;

export default engineRulesSlice.reducer;
