import EngineService from '../core/engine-service';

const ENDPOINT = '/smart_quotes/quotes';

export function getQuotes(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function getQuote(id, params = {}) {
  return EngineService.get(`${ENDPOINT}/${id}`, params).then((json) => json);
}

export function createQuote(payload) {
  return EngineService.post(ENDPOINT, { quote: payload }).then((json) => json);
}

export function cloneQuote(quoteID) {
  return EngineService.post(`${ENDPOINT}/${quoteID}/clone`).then((json) => json);
}

export function deleteQuote(quoteId) {
  return EngineService.delete(`${ENDPOINT}/${quoteId}`);
}

export function updateQuote(quoteId, payload) {
  return EngineService.patch(`${ENDPOINT}/${quoteId}`, { quote: payload }).then((json) => json);
}

export function getQuoteProducts(quoteId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${quoteId}/products`, params).then((json) => json);
}

export function createQuoteProducts(quoteId, payload) {
  return EngineService.post(`${ENDPOINT}/${quoteId}/products`, { product: payload }).then(
    (json) => json
  );
}

export function bulkCreateQuoteProducts(quoteId, payload) {
  return EngineService.post(`${ENDPOINT}/${quoteId}/products/bulk_create`, {
    products: { items: payload },
  }).then((json) => json);
}

export function bulkUpdateQuoteProducts(quoteId, payload) {
  return EngineService.patch(`${ENDPOINT}/${quoteId}/products/bulk_update`, payload).then(
    (json) => json
  );
}

export function bulkDeleteQuoteProducts(quoteId, payload) {
  return EngineService.post(`${ENDPOINT}/${quoteId}/products/bulk_destroy`, payload).then(
    (json) => json
  );
}

export function updateQuoteProduct(quoteId, productId, payload) {
  return EngineService.patch(`${ENDPOINT}/${quoteId}/products/${productId}`, {
    product: payload,
  }).then((json) => json);
}

export function deleteQuoteProduct(quoteId, productId) {
  return EngineService.delete(`${ENDPOINT}/${quoteId}/products/${productId}`);
}

export function getQuoteFilterProperties(params = {}) {
  return EngineService.get('/filters/quotes/columns', params).then((json) => json);
}

export function getQuotesCount(params = {}) {
  return EngineService.get(`${ENDPOINT}/count`, params);
}

export function getQuoteSummary(id, params = {}) {
  return EngineService.get(`${ENDPOINT}/${id}/quote_summary`, params).then((json) => json);
}

export function updateQuoteSummary(quoteId, payload) {
  return EngineService.patch(`${ENDPOINT}/${quoteId}/quote_summary`, {
    quote_summary: payload,
  }).then((json) => json);
}
