import { forwardRef } from 'react';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';

import styles from '../styles/ConversationsButtonStyles.module.scss';

const UploadButton = forwardRef((props, ref) => (
  <label htmlFor="file" className={styles['file-input']}>
    <div
      className={
        (props.uploading && props.progress < 100) || props.disabled
          ? styles['icon-wrapper-disabled']
          : styles['icon-wrapper']
      }
    >
      <Tooltip
        title="Upload image or video"
        arrow
        classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
      >
        <AddPhotoAlternateOutlinedIcon />
      </Tooltip>
    </div>
    <ReactS3Uploader
      ref={ref}
      id="file"
      name="file"
      className={styles.uploader}
      disabled={props.isLoading || props.disabled || (props.uploading && props.progress < 100)}
      getSignedUrl={props.getSignedUrl}
      preprocess={props.onUploadStart}
      onProgress={props.onUploadProgress}
      onError={props.onUploadError}
      onFinish={props.onUploadFinish}
      accept="image/*,video/*"
      // this is to prevent sending 'x-amz-acl': 'public-read' for security reasons
      uploadRequestHeaders={{}}
    />
  </label>
));

UploadButton.displayName = 'UploadButton';

UploadButton.propTypes = {
  uploading: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSignedUrl: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadProgress: PropTypes.func.isRequired,
  onUploadError: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default UploadButton;
