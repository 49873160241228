import { forwardRef } from 'react';

import Collapse from '@mui/material/Collapse';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import ContactAvatar from 'components/ContactAvatar';
import { getFullName } from 'helpers/contacts';

import styles from './ConversationContactCard.module.scss';

const getAllPhoneNumbersForContact = (contact) => {
  const phoneNumbers = [];
  if (contact.mobile_phone)
    phoneNumbers.push({ label: 'Mobile', phoneNumber: contact.mobile_phone });
  if (contact.phone) phoneNumbers.push({ label: 'Phone', phoneNumber: contact.phone });
  if (contact.home_phone)
    phoneNumbers.push({ label: 'Home Phone', phoneNumber: contact.home_phone });
  return phoneNumbers;
};

const ConversationContactCard = forwardRef(
  ({ contact, onClick, setSelectedContact, selectedContact = null }, ref) => {
    const phoneNumbers = getAllPhoneNumbersForContact(contact);

    const handleOnClick = () => {
      if (phoneNumbers.length > 1) {
        setSelectedContact(contact.id === selectedContact?.id ? null : contact);
      } else {
        onClick({ phone_number: phoneNumbers[0].phoneNumber }, contact);
      }
    };

    const handleOnPhoneClick = (phone) => {
      onClick({ phone_number: phone }, contact);
    };

    return (
      <div ref={ref} className={contact.id === selectedContact?.id ? styles.background : ''}>
        <div className={styles.container} onClick={handleOnClick} role="button" tabIndex="-1">
          <ContactAvatar contact={contact} />
          <div className={styles.details}>
            <div className={styles.title}>{getFullName(contact)}</div>
            <div className={styles.subtitle}>{contact.company.name}</div>
          </div>
        </div>
        <Collapse in={selectedContact?.id === contact.id}>
          <div>
            {phoneNumbers.map((phone) => (
              <div
                key={uuidv4()}
                className={styles['dropdown-item']}
                onClick={() => handleOnPhoneClick(phone.phoneNumber)}
                role="button"
                tabIndex="-1"
              >
                <div className={styles.title}>
                  {parsePhoneNumberFromString(phone.phoneNumber)?.format('NATIONAL')}
                </div>
                <div className={styles.subtitle}>{phone.label}</div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    );
  }
);

ConversationContactCard.displayName = 'ConversationContactCard';

ConversationContactCard.propTypes = {
  contact: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  setSelectedContact: PropTypes.func.isRequired,
  selectedContact: PropTypes.object,
};

export default ConversationContactCard;
