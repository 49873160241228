import { forwardRef, useEffect, useState } from 'react';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

import useConversation from 'hooks/useConversation';
import useCall from 'lib/CallButton/useCall';

import VoicemailDetails from './VoicemailDetails';
import VoicemailDetailsAndTranscriptions from './VoicemailDetailsAndTranscriptions';
import DateHelper from '../../../../helpers/DateHelper';
import RecentsAvatar from '../RecentsCard/RecentsAvatar';

import styles from './VoicemailCard.module.scss';

const VoicemailCard = forwardRef(
  ({ voicemail, setSelectedVoicemail, selectedVoicemail = null }, ref) => {
    const startCall = useCall();
    const [open, setOpen] = useState(false);
    const { showConversation } = useConversation();

    const onClick = () => {
      if (open) {
        setSelectedVoicemail(null);
      } else setSelectedVoicemail(voicemail.id);
      setOpen(!open);
    };

    const getResourceFromCall = (call) => {
      if (call.account) return 'Account';
      if (call.contact) return 'Contact';
      return '';
    };

    useEffect(() => {
      if (!selectedVoicemail && open) setOpen(false);
    }, [selectedVoicemail]);

    const initiateCall = () => {
      const resource = getResourceFromCall(voicemail.phone_call_log);
      startCall(voicemail.phone_call_log.from_number, resource);
    };

    const startConversation = () => {
      showConversation({ phone_number: voicemail.phone_call_log.from_number });
    };

    const displayVoicemailDuration = (time) => {
      if (time && !Number.isNaN(time)) {
        const minutes = Math.floor(time / 60);
        const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.ceil(time % 60);
        const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        if (minutes === 0) return `0:${formatSeconds}`;
        return `${formatMinutes}:${formatSeconds}`;
      }
      return '0:00';
    };

    return (
      <div className={open ? styles['extended-card'] : styles.card} ref={ref}>
        <div>
          <div className={styles.container} onClick={onClick} role="button" tabIndex={0}>
            <RecentsAvatar
              recent={{ ...voicemail.phone_call_log, confirmed: voicemail.confirmed }}
            />
            <VoicemailDetails voicemail={voicemail} extended={open} />
            {!open && (
              <div className={styles.date}>
                <div>{DateHelper.formatDateForVoicemails(voicemail.created_at)}</div>
                <div>{displayVoicemailDuration(voicemail.duration)}</div>
              </div>
            )}
            {open && (
              <div className={styles.actions}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    initiateCall();
                  }}
                >
                  <PhoneOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    startConversation();
                  }}
                >
                  <ChatOutlinedIcon />
                </IconButton>
              </div>
            )}
          </div>
          <Collapse in={open} timeout={300} unmountOnExit>
            <VoicemailDetailsAndTranscriptions voicemail={voicemail} />
          </Collapse>
        </div>
      </div>
    );
  }
);

VoicemailCard.displayName = 'VoicemailCard';

export default VoicemailCard;

VoicemailCard.propTypes = {
  setSelectedVoicemail: PropTypes.func.isRequired,
  voicemail: PropTypes.object.isRequired,
  selectedVoicemail: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};
