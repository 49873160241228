import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import SanitizedText from 'ui/SanitizedText';

import { useSearch } from '../SearchProvider';
import { ReactComponent as CompanyIcon } from './company.svg';

import styles from './SearchResult.module.scss';

export default function AccountResult({ account }) {
  const { setVisible } = useSearch();

  return (
    <Link
      to={`/companies/${account.id}`}
      onClick={() => setVisible(false)}
      className={styles['search-result']}
    >
      <div className={styles['primary-col']}>
        <CompanyIcon className={styles.icon} />
        <div>
          <SanitizedText as="p" text={account.getName()} />
          <div>
            {account.address && (
              <SanitizedText
                as="span"
                className={styles['secondary-field']}
                text={account.address}
              />
            )}
            {account.city && (
              <SanitizedText as="span" className={styles['secondary-field']} text={account.city} />
            )}
            {account.state && (
              <SanitizedText as="span" className={styles['secondary-field']} text={account.state} />
            )}
            {account.zipcode && (
              <SanitizedText
                as="span"
                className={styles['secondary-field']}
                text={account.zipcode}
              />
            )}
            {account.country && (
              <SanitizedText
                as="span"
                className={styles['secondary-field']}
                text={account.country}
              />
            )}
          </div>
          {account.phone && (
            <div className={styles['secondary-field']}>
              <strong>Phone: </strong>
              <SanitizedText as="span" text={account.phone} />
            </div>
          )}
          {account.externalID && (
            <div className={styles['secondary-field']}>
              <strong>External ID: </strong>
              <SanitizedText as="span" text={account.externalID} />
            </div>
          )}
          {account.customText && (
            <div className={styles['secondary-field']}>
              <strong>Custom: </strong>
              {account.customText.map((matchedFragment) => (
                <SanitizedText
                  key={uuidv4()}
                  as="span"
                  className={styles['description-fragment']}
                  text={matchedFragment}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <SanitizedText className={styles['secondary-col']} text={account.getDomain()} />
    </Link>
  );
}

AccountResult.propTypes = {
  account: PropTypes.object.isRequired,
};
