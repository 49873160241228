import EngineService from '../core/engine-service';

const ENDPOINT = '/administration';

export function getUsers(params = {}) {
  return EngineService.get(`${ENDPOINT}/users`, params).then((json) => json);
}

export function createUser(payload) {
  return EngineService.post(`${ENDPOINT}/users`, payload).then((json) => json);
}

export function getSuggestedUsers(params = {}) {
  return EngineService.get(`${ENDPOINT}/suggested_users`, params).then((json) => json);
}

export function createSuggestedUser(payload) {
  return EngineService.post(`${ENDPOINT}/suggested_users`, payload).then((json) => json);
}

export function requestLicenses(payload) {
  return EngineService.post(`${ENDPOINT}/request_licenses`, payload).then((json) => json);
}

export function handleUserBulkActions(payload) {
  return EngineService.patch(`${ENDPOINT}/users/bulk_update`, payload).then((json) => json);
}

export function bulkRemoveSuggested(payload) {
  return EngineService.post(`${ENDPOINT}/suggested_users/bulk_remove`, payload).then(
    (json) => json
  );
}

export function updateAdminUser(userId, data) {
  return EngineService.patch(`${ENDPOINT}/users/${userId}`, {
    user: data,
  }).then((json) => json.user);
}
