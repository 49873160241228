import { createAsyncThunk } from '@reduxjs/toolkit';

import { serializeFiltersToString } from 'app/helpers/filters';
import { updateRequestedAccess } from 'app/services/spiro-access';
import {
  bulkRemoveSuggested,
  createSuggestedUser,
  createUser as createUserRemote,
  getSuggestedUsers,
  getUsers,
  handleUserBulkActions,
  requestLicenses as requestLicensesRemote,
  updateAdminUser,
} from 'app/services/user-administration';
import {
  closeUserAdminModal,
  openUserAdminModal,
} from 'app/state/modals/user-administration/reducers';
import { serialize } from 'helpers/user';
import { parseAll, parse as parseUser } from 'helpers/users';
import { error as errorAlert, success as successAlert } from 'state/notifications/actions';

import { setSelectedUserIds } from './reducers';
import { forEachError } from '../../../../../helpers/errorHelper';
import { fetchFilterProperties, fetchSavedFilters } from '../../../../state/filters/users/actions';
import {
  getLicenseTypeLabel,
  mainLicenses,
  serializeFieldsForBulkActions,
  serializeValue,
} from '../helpers';

function getParamsFromState(state) {
  const params = state.userAdministration.params;
  const currentFilters = state.filters.users.currentFilters;

  return {
    ...params,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchUsers = createAsyncThunk(
  'administration-users-get',
  async (params = { include_deactivated: true }, { dispatch, getState }) => {
    try {
      await dispatch(fetchFilterProperties());
      await dispatch(fetchSavedFilters());
      const stateParams = await getParamsFromState(getState());

      const payload = { ...stateParams, ...params };
      const res = await getUsers(payload);
      return Promise.resolve(parseAll(res.users));
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export const fetchSuggestedUsers = createAsyncThunk(
  'administration-suggested-users-get',
  async (params = null, { getState }) => {
    try {
      const stateParams = getState().userAdministration.suggestedUsersParams;
      const payload = { ...stateParams, ...params };
      const res = await getSuggestedUsers(payload);
      return Promise.resolve(res.suggested_users);
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export const requestLicenses = createAsyncThunk(
  'administration-request-licenses',
  async (payload, { dispatch }) => {
    try {
      const res = await requestLicensesRemote(payload);
      dispatch(closeUserAdminModal());
      dispatch(successAlert(`${getLicenseTypeLabel(payload.license_type)} added.`));
      return Promise.resolve(res.spiro_subscription);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const handleBulkActions = createAsyncThunk(
  'administration-bulk-actions',
  async (payload, { dispatch, getState }) => {
    try {
      const userIds = getState().userAdministration.selectedUserIds;
      const users = getState().userAdministration.users;

      const fullName =
        payload.type === 'parent_id' &&
        users.find((user) => user.id === payload.data[payload.type]).fullName;

      const params = {
        bulk_update: { user_ids: userIds, [payload.type]: payload.data[payload.type] },
      };

      const newValues = {
        userIds,
        field: serializeFieldsForBulkActions(payload.type),
        value: serializeValue(payload, fullName),
      };

      await handleUserBulkActions(params);
      dispatch(closeUserAdminModal());
      dispatch(setSelectedUserIds([]));
      return Promise.resolve(newValues);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  'administration-user-update',
  async (payload, { dispatch, getState }) => {
    try {
      const res = await updateAdminUser(payload.userId, serialize(payload.payload));
      const parsedResponse = parseUser(res);
      const { fullName } = parsedResponse;
      const users = getState().userAdministration.users;

      const nestedKey = Object.keys(payload.payload)[0];

      if (mainLicenses.includes(Object.values(payload.payload)[0])) {
        dispatch(
          successAlert(
            `${getLicenseTypeLabel(Object.values(payload.payload)[0])} assigned to ${fullName}`
          )
        );
      }

      if (nestedKey === 'notetakerEnabled')
        dispatch(successAlert(`Notetaker license added to ${fullName}!`));

      if (nestedKey === 'phoneEnabled') {
        dispatch(successAlert(`Phone license added to ${fullName}!`));
        dispatch(
          openUserAdminModal({
            name: 'ASSIGN_PHONE_NUMBER_MODAL',
            props: {
              visible: true,
              onHide: () => dispatch(closeUserAdminModal()),
              userId: payload.userId,
              users,
            },
          })
        );
      }

      return Promise.resolve(parsedResponse);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const rejectRequestedAccess = createAsyncThunk(
  'administration-user-reject',
  async (payload, { dispatch }) => {
    try {
      const res = await updateRequestedAccess(payload, 'rejected');

      return Promise.resolve(res.access_request);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const bulkRemoveSuggestedUsers = createAsyncThunk(
  'administration-user-bulk-remove',
  async (_, { dispatch, getState }) => {
    try {
      const userIds = getState().userAdministration.selectedUserIds;

      await bulkRemoveSuggested({ ids: userIds });

      return Promise.resolve(userIds);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const inviteUser = createAsyncThunk(
  'administration-user-invite',
  async (payload, { dispatch }) => {
    try {
      const res = await createUserRemote({ user: payload });
      const newUser = parseUser(res.user);
      dispatch(successAlert(`${newUser.firstName} ${newUser.lastName} successfully added.`));
      dispatch(closeUserAdminModal());
      return Promise.resolve(newUser);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const inviteSuggestedUser = createAsyncThunk(
  'administration-user-invite-suggested',
  async (payload, { dispatch }) => {
    try {
      const res = await createSuggestedUser({ id: payload.id, user: payload.user });
      const newUser = parseUser(res.user);
      dispatch(successAlert(`${newUser.firstName} ${newUser.lastName} successfully added.`));
      dispatch(closeUserAdminModal());
      return Promise.resolve({ user: newUser, id: payload.id });
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const deactivateAndReasignLicense = createAsyncThunk(
  'administration-user-reassign',
  async (payload, { dispatch, getState }) => {
    try {
      const res = await updateAdminUser(payload.userId, serialize(payload.payload));
      const deactivatedUser = parseUser(res);
      const userWithNewLicense = {
        id: payload.payload.reassignedTo,
        payload: {
          licenseType: payload.licenseType,
          rawLicenseType: payload.rawLicenseType,
        },
      };

      const users = getState().userAdministration.users;
      const fullName = users.find((user) => user.id === payload.payload.reassignedTo).fullName;
      dispatch(successAlert(`${payload.licenseType} assigned to ${fullName}.`));

      return Promise.resolve({ deactivatedUser, userWithNewLicense });
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);
