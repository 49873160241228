import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import PropTypes from 'prop-types';

const StyledMuiTab = styled(MuiTab)(({ uppercaseText, noMinWidth }) => ({
  color: '#999999',
  '&.Mui-selected': {
    color: '#5D43CF !important',
    opacity: 1,
  },
  textTransform: uppercaseText ? 'none' : 'uppercase',
  minWidth: uppercaseText || noMinWidth ? '0px' : '160px',
  fontSize: noMinWidth ? '13px' : '14px',
}));

export default function Tab(props) {
  const { uppercaseText = false, noMinWidth = false, ...rest } = props;
  return <StyledMuiTab uppercaseText={uppercaseText} noMinWidth={noMinWidth} {...rest} />;
}

Tab.propTypes = {
  uppercaseText: PropTypes.bool,
  noMinWidth: PropTypes.bool,
};
