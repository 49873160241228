import { memo, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import UnreadMessagesButton from 'app/components/Chat/UnreadMessagesButton';
import { markThreadAsRead } from 'app/services/spiro-phone';
import { groupMessagesByDate } from 'helpers/twilio';
import { error as errorAlert } from 'state/notifications/actions';

import { forEachError } from '../../../../../../helpers/errorHelper';
import { selectTwilioProfile } from '../../../../../state/user/selectors';
import Chat from '../../../../Chat/Chat';
import LoadMoreButton from '../../../../Chat/LoadMoreButton';
import MessageForm from '../../../../Conversation/MessageForm';
import { fetchSingleMessage } from '../state/actions';
import { selectChatId, selectMeta } from '../state/selectors';

import styles from './ChatWindow.module.scss';

const ChatWindow = memo(
  ({
    displayUnreadMessagesButton,
    setDisplayUnreadMessagesButton,
    data = [],
    contact = null,
    phone = '',
  }) => {
    const ref = useRef();
    const dispatch = useDispatch();
    const twilioProfile = useSelector(selectTwilioProfile);
    const meta = useSelector(selectMeta);
    const chatId = useSelector(selectChatId);
    const [scrollTrigger, setScrollTrigger] = useState(false);

    let prevScrollHeight = '';

    const markAsRead = async () => {
      try {
        await markThreadAsRead(chatId);
      } catch (error) {
        forEachError(error.data, (e) => dispatch(errorAlert(e)));
      }
    };

    const handleUnreadMessages = () => {
      setDisplayUnreadMessagesButton(false);
      scrollToBottom();
      markAsRead();
    };

    function scrollToBottom() {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }

    function setNewScrollTop() {
      ref.current.scrollTop = ref.current.scrollHeight - prevScrollHeight;
    }

    function setPrevScrollHeight() {
      prevScrollHeight = ref.current.scrollHeight;
    }

    const handleLoadMore = () => {
      setPrevScrollHeight();
      dispatch(fetchSingleMessage({ id: chatId, params: { offset: data.length } })).then(() => {
        requestAnimationFrame(() => {
          setNewScrollTop();
        });
      });
    };

    const handleScroll = () => {
      if (!ref.current) return;

      const { scrollTop, scrollHeight, clientHeight } = ref.current;

      const atBottom = scrollHeight - scrollTop <= clientHeight + 1;
      if (displayUnreadMessagesButton && atBottom) {
        handleUnreadMessages();
      }
    };

    const onSuccess = () => {
      setScrollTrigger(true);
    };

    useEffect(() => {
      if (data.length) {
        scrollToBottom();
      }
    }, []);

    useEffect(() => {
      if (scrollTrigger) {
        scrollToBottom();
        setScrollTrigger(false);
      }
    }, [scrollTrigger]);

    return (
      <div className={styles.container}>
        <div className={styles.chat} ref={ref} onScroll={handleScroll}>
          <LoadMoreButton visible={meta.nextPage} onClick={handleLoadMore} />
          <Chat messages={groupMessagesByDate(data)} />
          <UnreadMessagesButton
            visible={displayUnreadMessagesButton}
            onClick={handleUnreadMessages}
          />
        </div>
        {twilioProfile && twilioProfile.profile_type !== 'verified_number' && phone && (
          <MessageForm contact={contact} phone={phone} onSuccess={onSuccess} maxRows="12" />
        )}
      </div>
    );
  }
);

ChatWindow.displayName = 'ChatWindow';

ChatWindow.propTypes = {
  displayUnreadMessagesButton: PropTypes.bool.isRequired,
  setDisplayUnreadMessagesButton: PropTypes.func.isRequired,
  data: PropTypes.array,
  contact: PropTypes.object,
  phone: PropTypes.string,
};

export default ChatWindow;
