import React, { useCallback, useContext, useMemo, useReducer } from 'react';

import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';

import { parseMetaFromResponse } from 'helpers/meta';
import useDebounce from 'hooks/useDebounce';
import useHotjar from 'hooks/useHotjar';
import { search } from 'services/search';

import ACTIONS from './actions';
import reducer, { initialState } from './reducer';
import resultFactory from '../resultFactory';

const SearchContext = React.createContext();

export const useSearch = () => useContext(SearchContext);

function SearchProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 200);
  const groupedResults = useMemo(() => groupBy(state.results, 'type'), [state.results]);
  const { logCustomEvent } = useHotjar();

  const setVisible = useCallback((visible) => {
    dispatch({ type: ACTIONS.SET_VISIBLE, visible });
  }, []);

  const setSearchTerm = useCallback((searchTerm) => {
    dispatch({ type: ACTIONS.SET_SEARCH_TERM, searchTerm });
  }, []);

  const setLoading = useCallback((loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading });
  }, []);

  const setResults = useCallback((results) => {
    dispatch({ type: ACTIONS.SET_RESULTS, results });
  }, []);

  const setMeta = useCallback((meta) => {
    dispatch({ type: ACTIONS.SET_META, meta });
  }, []);

  const setParams = useCallback((params) => {
    dispatch({ type: ACTIONS.SET_PARAMS, params });
  }, []);

  const setCleanState = useCallback(() => {
    dispatch({ type: ACTIONS.SET_CLEAN_STATE });
  }, []);

  const setInitialState = useCallback(() => {
    dispatch({ type: ACTIONS.SET_INITIAL_STATE });
  }, []);

  const fetchSearchResults = useCallback(async () => {
    setLoading(true);

    const response = await search({ query: debouncedSearchTerm, ...state.params });
    const results = response.search.map((result) => resultFactory.create(result));
    const meta = parseMetaFromResponse(response.meta);

    setResults(results);
    setMeta(meta);
    setLoading(false);
    logCustomEvent('global_search_results_fetched');
  }, [debouncedSearchTerm, state.params, setResults, setMeta, setLoading, logCustomEvent]);

  const contextValue = useMemo(
    () => ({
      ...state,
      debouncedSearchTerm,
      groupedResults,
      setVisible,
      setSearchTerm,
      setLoading,
      setResults,
      setMeta,
      setParams,
      setCleanState,
      setInitialState,
      fetchSearchResults,
    }),
    [
      state,
      debouncedSearchTerm,
      groupedResults,
      setVisible,
      setSearchTerm,
      setLoading,
      setResults,
      setMeta,
      setParams,
      setCleanState,
      setInitialState,
      fetchSearchResults,
    ]
  );

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchProvider;
