import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import TextField from 'lib/TextField';

export default function PercentageField({ adornmentPlacement = 'end', ...props }) {
  const adornment =
    adornmentPlacement === 'end'
      ? { endAdornment: <InputAdornment position="start">%</InputAdornment> }
      : { startAdornment: <InputAdornment position="start">%</InputAdornment> };

  return (
    <TextField
      type="number"
      {...props}
      InputProps={{
        ...adornment,
      }}
    />
  );
}

PercentageField.propTypes = {
  adornmentPlacement: PropTypes.string,
};
