import { useEffect, useRef, useState } from 'react';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseIcon from '@mui/icons-material/Close';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import IconButton from '@mui/material/IconButton';
import { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import DrawerNavigation from '../DrawerNavigation/DrawerNavigation';
import SpiroTooltip from '../SpiroTooltip';

import styles from './DetailsDrawerHeader.module.scss';

function DetailsDrawerHeader({
  onClose,
  icon = null,
  title = '',
  date = '',
  callDuration = '',
  drawerNavigationProps = null,
}) {
  const titleRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (titleRef.current) {
        setIsTruncated(titleRef.current.scrollWidth > titleRef.current.clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [title]);

  return (
    <div className={styles.header} data-cy="drawer-header">
      <div className={styles.title}>
        <span className={styles.icon}>{icon}</span>
        {isTruncated ? (
          <SpiroTooltip
            title={title}
            placement="bottom"
            arrow
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                marginTop: '5px !important',
              },
            }}
          >
            <Typography ref={titleRef} variant="h5">
              {title}
            </Typography>
          </SpiroTooltip>
        ) : (
          <Typography ref={titleRef} variant="h5" data-cy="drawer-title">
            {title}
          </Typography>
        )}
      </div>
      <div className={styles.info}>
        {date && (
          <div className={styles.date}>
            <CalendarTodayOutlinedIcon fontSize="small" />
            <Typography variant="body" data-cy="drawer-date" noWrap>
              {date}
            </Typography>
          </div>
        )}
        {callDuration && (
          <div className={styles['call-duration']}>
            <WatchLaterOutlinedIcon fontSize="small" />
            <Typography variant="body" data-cy="drawer-call-duration">
              {callDuration}
            </Typography>
          </div>
        )}
      </div>
      <DrawerNavigation drawerNavigationProps={drawerNavigationProps} />
      <IconButton aria-label="close" onClick={onClose} size="large" data-cy="close-btn">
        <CloseIcon />
      </IconButton>
    </div>
  );
}

DetailsDrawerHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string,
  date: PropTypes.string,
  callDuration: PropTypes.string,
  drawerNavigationProps: PropTypes.object,
};

export default DetailsDrawerHeader;
