import PropTypes from 'prop-types';

export default function TabPanel(props) {
  const { children, value, index, className = '', style = {} } = props;

  return (
    <div role="tabpanel" hidden={value !== index} className={className} style={style}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.array]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
