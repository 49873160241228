import { useEffect, useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal, showModal } from 'app/modals/state/actions';
import DragAndDropArea from 'components/TemplatesSettings/EmailBodyEditor/DragAndDropArea';
import { selectMentionableItems } from 'state/user/selectors';

import useNotesEditor from './useNotesEditor';

import styles from './NotesEditor.module.scss';

function NotesEditor({ onChange, value, isActive, handleSetAttachments, onDelete }) {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [newAttachment, setNewAttachment] = useState(null);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const mentionableItems = useSelector(selectMentionableItems);
  const { uploadFile, getAttachmentHTML } = useNotesEditor();

  const toggleIsFocused = () => setIsFocused((prevState) => !prevState);

  const saveAttachments = (attachments) => {
    const newAttachments = [];
    attachments.forEach((attachment) => {
      const attachmentHTML = getAttachmentHTML(attachment);
      editorRef.current.selection.setContent(attachmentHTML);
      newAttachments.push(attachment.link);
    });
    handleSetAttachments(newAttachments);
  };

  const openAddMentionModal = () => {
    dispatch(
      showModal('ADD_MENTION_MODAL', {
        show: true,
        onClose: () => dispatch(hideModal()),
      })
    );
  };

  useEffect(() => {
    if (newAttachment) handleSetAttachments([newAttachment]);
  }, [newAttachment]);

  useEffect(() => {
    onDelete(attachmentLinks);
  }, [attachmentLinks]);

  useEffect(() => {
    if (isActive && editorRef.current) editorRef.current.focus();
  }, [isActive]);

  return (
    <div
      className={cs('notes-editor', {
        [styles.wrapper]: isFocused && !isDragging,
        [styles['out-of-focus-wrapper']]: !isFocused && !isDragging,
      })}
    >
      <DragAndDropArea
        uploadFile={uploadFile}
        fullWidth
        saveAttachments={saveAttachments}
        setIsDragging={setIsDragging}
        isDragging={isDragging}
      />
      <Editor
        apiKey={process.env.TINYMCE_API_KEY}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          tinymce.activeEditor.focus();
          tinymce.activeEditor.selection.select(tinymce.activeEditor.getBody(), true);
          tinymce.activeEditor.selection.collapse(false);
        }}
        value={value}
        init={{
          placeholder: 'Type @ to mention and notify an individual or group',
          height: 145,
          elementpath: false,
          branding: false,
          menubar: false,
          forced_root_block: 'div',
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,400;1,500&display=swap'); body { font-family: Roboto; line-height: 24px; } .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: rgba(179, 179, 179, 1); opacity: 1; } .spiro-mention { color: #5d43cf; display: inline-block; height: 20px; padding: 0px 8px; line-height: 20px; border-radius: 30px; background-color: rgba(174, 161, 231, 0.24); }`,
          plugins: [
            'link',
            'image',
            'attachment',
            'lists',
            'emoticons',
            'paste',
            'tinymcespellchecker',
            'noneditable',
            'mentions',
          ],
          paste_as_text: true,
          contextmenu: false,
          convert_urls: false,
          block_unsupported_drop: false,
          spellchecker_language: 'en',
          extended_valid_elements:
            'svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*]',
          toolbar:
            'undo redo | bold italic underline strikethrough | textaligngroup numlist bullist removeformat | spellcheckdialog attachment mention-user emoticons',
          toolbar_groups: {
            textaligngroup: {
              icon: 'align-left',
              tooltip: 'Align',
              items: 'alignleft aligncenter alignright ',
            },
          },
          file_picker_callback: (cb) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.onchange = () => {
              const file = input.files[0];
              const btn = document.querySelector('[title="Save"]');
              if (btn) {
                btn.classList.add('tinymce-btn');
                btn.setAttribute('disabled', 'disabled');
                btn.classList.add('tinymce-loading');
                btn.textContent = 'Uploading...';
              }
              uploadFile(file).then((response) => {
                cb(response.data.link, response.data);
                if (btn) {
                  btn.removeAttribute('disabled');
                  btn.classList.remove('tinymce-loading');
                  btn.textContent = 'Save';
                }
              });
            };
            input.click();
          },
          mentions_selector: 'span.spiro-mention',
          mentions_item_type: 'profile',
          mentions_min_chars: 0,
          mentions_menu_complete: (editor, userInfo) => {
            if (userInfo.id === 'button') {
              openAddMentionModal();
            }
            const span = editor.getDoc().createElement('span');
            span.className = 'spiro-mention';
            span.setAttribute('data-mention-id', userInfo.id);
            span.setAttribute('data-mention-type', userInfo.type);
            span.style.color = '#5d43cf';
            span.style.display = 'inline-block';
            span.style.height = '20px';
            span.style.padding = '0px 8px';
            span.style['line-height'] = '20px';
            span.style['border-radius'] = '30px';
            span.style['background-color'] = 'rgba(174, 161, 231, 0.24)';
            span.appendChild(editor.getDoc().createTextNode(`${userInfo.spanText || ''}`));
            editor.fire('mentionAdded');

            return span;
          },
          mentions_fetch(query, success) {
            const email = {
              id: 'button',
              name: 'Mention Via Email',
              username: 'emailbutton',
              // image: 'add links to custom images',
            };

            const items = mentionableItems
              .filter((user) => user.name.toLowerCase().includes(query.term.toLowerCase()))
              .slice(0, 9);

            items.push(email);

            success(items);
          },
          setup: (editor) => {
            tinymce.PluginManager.add('attachment', () => {
              const openDialog = () =>
                editor.windowManager.open({
                  title: 'Insert attachment',
                  body: {
                    type: 'panel',
                    items: [
                      {
                        type: 'urlinput',
                        name: 'attachment',
                        label: 'Attachment',
                        id: 'attachment',
                      },
                    ],
                  },
                  buttons: [
                    {
                      type: 'cancel',
                      text: 'Cancel',
                    },
                    {
                      type: 'submit',
                      text: 'Save',
                      primary: true,
                      enabled: false,
                    },
                  ],
                  onSubmit(api) {
                    const data = api.getData();
                    const attachmentHTML = getAttachmentHTML(data.attachment.meta);
                    setNewAttachment(data.attachment.meta.link);
                    editor.selection.setContent(attachmentHTML);
                    api.close();
                  },
                });
              editor.ui.registry.addIcon(
                'insert-attachment',
                '<span style="font-size: 18px; font-family:Material Icons; color: #999;">attach_file</i></span>'
              );
              editor.ui.registry.addIcon(
                'strike-through',
                '<span style="font-family:Material Icons; color: #999; display: flex; height: 24px; align-items: flex-end; font-size: 22px;">strikethrough_s</span>'
              );
              editor.ui.registry.addIcon(
                'mention-user',
                '<span style="font-size: 18px; font-family:Material Icons; color: #999;">alternate_email</i></span>'
              );
              editor.ui.registry.addButton('attachment', {
                icon: 'insert-attachment',
                tooltip: 'Insert attachment',
                onAction: openDialog,
              });
              editor.ui.registry.addButton('mention-user', {
                icon: 'mention-user',
                tooltip: 'Mention user',
                onAction: () => {
                  editor.fire('keydown', { keyCode: 16 });
                  editor.fire('keydown', { keyCode: 50 });

                  editor.fire('keypress', { keyCode: 16 });
                  editor.fire('keypress', { keyCode: 50 });

                  editor.selection.setContent('@');
                  editor.fire('keyup', { keyCode: 16 });
                  editor.fire('keyup', { keyCode: 50 });
                },
              });
              editor.on('mentionAdded', () => {
                setTimeout(() => {
                  editor.insertContent('&nbsp;');
                  const rng = editor.selection.getRng();
                  rng.setStart(rng.endContainer, rng.endOffset);
                  rng.setEnd(rng.endContainer, rng.endOffset);
                  editor.selection.setRng(rng);
                }, 100);
              });
              editor.on('dragover', (e) => {
                e.preventDefault();
                if (!isDragging) setIsDragging(true);
              });
              editor.on('KeyUp', (e) => {
                if (e.keyCode === 8 || e.keyCode === 46) {
                  const content = tinymce.activeEditor.getContent();
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(content, 'text/html');
                  const attachments = [...doc.getElementsByClassName('spiro-attachment')];
                  const attachmentsLinks = [];
                  [...attachments].forEach((l) => attachmentsLinks.push(l.href));
                  setAttachmentLinks(attachmentsLinks);
                }
              });
              editor.on('paste', (e) => {
                if (
                  e.clipboardData.files.length > 0 &&
                  e.clipboardData.files[0].type.includes('image')
                ) {
                  editor.setProgressState(true);
                  e.preventDefault();
                  uploadFile(e.clipboardData.files[0]).then((response) => {
                    const img = new Image();
                    img.src = response.data.link;
                    const range = editor.selection.getRng();
                    const newNode = editor.getDoc().createElement('a');
                    newNode.setAttribute('class', 'spiro-attachment');
                    newNode.setAttribute('href', img.src);
                    newNode.setAttribute('target', '_blank');
                    newNode.style.display = 'inline-block';
                    newNode.style['max-width'] = '50%';
                    img.style.width = '100%';
                    newNode.append(img);
                    range.insertNode(newNode);
                    editor.selection.collapse(false);
                    editor.setProgressState(false);
                    onChange(editor.getContent());
                  });
                }
              });
            });
          },
          icons: 'material',
        }}
        onEditorChange={onChange}
        onBlur={toggleIsFocused}
        onFocus={toggleIsFocused}
      />
    </div>
  );
}

NotesEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleSetAttachments: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default NotesEditor;
