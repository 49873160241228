import {
  HIDE_EMAIL_MODAL_DIALOG,
  HIDE_MODAL_DIALOG,
  MINIMIZE_EMAIL_DIALOG,
  SHOW_EMAIL_MODAL_DIALOG,
  SHOW_MODAL_DIALOG,
} from './constants';

export function showModal(name, props) {
  return {
    type: SHOW_MODAL_DIALOG,
    name,
    props,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL_DIALOG,
  };
}

export function minimizeEmailModal() {
  return {
    type: MINIMIZE_EMAIL_DIALOG,
  };
}

export function showEmailModal(props) {
  return {
    type: SHOW_EMAIL_MODAL_DIALOG,
    props,
  };
}

export function hideEmailModal() {
  return {
    type: HIDE_EMAIL_MODAL_DIALOG,
  };
}
