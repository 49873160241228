import { createAsyncThunk } from '@reduxjs/toolkit';

import { hideModal } from 'app/modals/state/actions';
import {
  createAssistantRulesFolder,
  deleteAssistantRulesFolder,
  getAssistantRulesFolders,
  updateAssistantRulesFolder,
} from 'app/services/assistant-rules-folders';
import {
  fetchFilterProperties,
  fetchSavedFilters,
} from 'app/state/filters/assistant-folders/actions';
import { serializeFiltersToString } from 'helpers/filters';
import { cloneRule, createEngineRule, getEngineRules } from 'services/engine-rules';
import { error, success as successAlert } from 'state/notifications/actions';

import { setIntialLoading } from './reducer';
import { forEachError } from '../../../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.engineRules.params;
  const currentFilters = state.filters.assistantRulesFolders.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.AssistantRule;

  return {
    ...params,
    sort: paramsFromViews.order_by,
    order: paramsFromViews.order_direction,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchEngineRules = createAsyncThunk(
  'engine-rules-get',
  async (params = {}, { getState }) => {
    const recordsPerPage = getState().spiroViews.recordsPerPage;
    const reqParams = { ...getState().engineRules.params, ...params };
    const defaultParams = { ...reqParams, per_page: recordsPerPage };

    return getEngineRules(defaultParams);
  }
);

export const handleCreateEngineRule = createAsyncThunk(
  'engine-rules-create',
  async (payload, { dispatch }) => {
    try {
      const res = await createEngineRule(payload);
      dispatch(successAlert('Assistant Rule created!'));
      dispatch(hideModal());
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleCloneEngineRule = createAsyncThunk(
  'engine-rules-clone',
  async (payload, { dispatch }) => {
    try {
      const res = await cloneRule(payload);
      dispatch(successAlert('Assistant Rule cloned!'));
      return res.assistant_rule;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchEngineRulesFolders = createAsyncThunk(
  'engine-rules-folders-get',
  async (params = {}, { dispatch, getState }) => {
    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      const isCached = getState().engineRules.data.length;

      if (!isCached) {
        dispatch(setIntialLoading(true));
      }

      return getAssistantRulesFolders(payload)
        .then((json) =>
          Promise.resolve({
            ...json,
            params: { ...stateParams, ...params },
          })
        )
        .catch((err) => {
          forEachError(err.data, (e) => dispatch(error(e)));
          return Promise.reject(err);
        });
    });
  }
);

export const createEngineRulesFolder = createAsyncThunk(
  'engine-rules-folders-create',
  async (payload, { dispatch }) => {
    try {
      const res = await createAssistantRulesFolder(payload);
      dispatch(successAlert('Folder created!'));
      dispatch(hideModal());
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const updateEngineRulesFolder = createAsyncThunk(
  'engine-rules-folders-update',
  async ({ id, ...payload }, { dispatch }) => {
    try {
      const res = await updateAssistantRulesFolder(id, payload);
      dispatch(successAlert('Folder updated successfully!'));
      dispatch(hideModal());
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const deleteEngineRulesFolder = createAsyncThunk(
  'engine-rules-folders-delete',
  async (id, { dispatch }) => {
    try {
      const res = await deleteAssistantRulesFolder(id);
      dispatch(successAlert('Folder deleted successfully!'));
      dispatch(hideModal());
      return { id, res };
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
