import { createSlice } from '@reduxjs/toolkit';

import { serializeLicenses } from '../helpers';
import {
  bulkRemoveSuggestedUsers,
  deactivateAndReasignLicense,
  fetchSuggestedUsers,
  fetchUsers,
  handleBulkActions,
  inviteSuggestedUser,
  inviteUser,
  rejectRequestedAccess,
  requestLicenses,
  updateUser,
} from './actions';

const initialState = {
  users: [],
  suggestedUsers: [],
  deactivatedUsers: [],
  isLoading: false,
  selectedTab: 0,
  selectedUser: null,
  selectedUserIds: [],
  licenseOwnerId: null,
  licenseId: null,
  licenses: [],
  params: {
    sort: undefined,
    order: undefined,
    q: undefined,
    include_deactivated: true,
    query: undefined,
  },
  suggestedUsersParams: {
    sort: undefined,
    order: undefined,
    query: undefined,
  },
};
export const userAdministrationSlice = createSlice({
  name: 'userAdministration',
  initialState,
  reducers: {
    setSelectedTab: (state, { payload }) => ({ ...state, selectedTab: payload }),
    setSelectedUser: (state, { payload }) => ({ ...state, selectedUser: payload }),
    setSelectedUserIds: (state, { payload }) => ({ ...state, selectedUserIds: payload }),
    updateTwilioData: (state, { payload }) => {
      const userIndex = state.users.findIndex((user) => user.id === payload.userId);
      const updatedUsers = state.users.map((user, index) =>
        index === userIndex ? { ...user, twilioProfile: payload.data } : user
      );
      return {
        ...state,
        users: updatedUsers,
      };
    },
    updateLicensesCount: (state, { payload }) => {
      const { type: licenseType, action: licenseAction } = payload;
      const updatedLicenses = state.licenses.map((license) => {
        if (license.singular === licenseType) {
          return {
            ...license,
            available:
              licenseAction === 'add'
                ? Math.min(license.total, license.available + 1)
                : Math.max(0, license.available - 1),
          };
        }
        return license;
      });
      return { ...state, licenses: updatedLicenses };
    },
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => ({ ...state, isLoading: true }),
    [fetchUsers.fulfilled]: (state, { payload }) => {
      const deactivatedUsers = [];
      const activeUsers = [];

      payload.forEach((user) => {
        (user.status === 'deactivated' ? deactivatedUsers : activeUsers).push(user);
      });

      const hasUsers = activeUsers.length > 0;
      return {
        ...state,
        deactivatedUsers,
        users: activeUsers,
        licenseOwnerId: hasUsers ? activeUsers[0].spiroSubscription.userId : state.licenseOwnerId,
        licenseId: hasUsers ? activeUsers[0].spiroSubscription.id : state.licenseId,
        licenses: hasUsers
          ? serializeLicenses(activeUsers[0].spiroSubscription.licenses)
          : state.licenses,
        isLoading: false,
      };
    },
    [fetchUsers.rejected]: (state) => ({ ...state, isLoading: false }),
    [fetchSuggestedUsers.pending]: (state) => ({ ...state, isLoading: true }),
    [fetchSuggestedUsers.fulfilled]: (state, { payload }) => ({
      ...state,
      suggestedUsers: payload,
      isLoading: false,
    }),
    [fetchSuggestedUsers.rejected]: (state) => ({ ...state, isLoading: false }),
    [requestLicenses.fulfilled]: (state, { payload }) => ({
      ...state,
      licenses: serializeLicenses(payload.licenses),
    }),
    [updateUser.fulfilled]: (state, { payload }) => {
      const allUsers = [...state.users, ...state.deactivatedUsers];
      const index = allUsers.map((u) => u.id).indexOf(payload.id);

      const updatedUsers = allUsers
        .slice(0, index)
        .concat(payload)
        .concat(allUsers.slice(index + 1));

      const deactivatedUsers = updatedUsers.filter((u) => u.status === 'deactivated');
      const activeUsers = updatedUsers.filter((u) => u.status !== 'deactivated');

      return {
        ...state,
        users: activeUsers,
        deactivatedUsers,
        licenseOwnerId: payload.spiroSubscription.userId,
        licenses: serializeLicenses(payload.spiroSubscription.licenses),
        licenseId: payload.spiroSubscription.id,
      };
    },
    [handleBulkActions.fulfilled]: (state, { payload }) => {
      const allUsers = [...state.users, ...state.deactivatedUsers];
      const updatedUsers = allUsers.map((user) => {
        if (payload.userIds.includes(user.id)) {
          return {
            ...user,
            [payload.field]: payload.value,
          };
        }
        return user;
      });
      const deactivatedUsers = updatedUsers.filter((u) => u.status === 'deactivated');
      const activeUsers = updatedUsers.filter((u) => u.status !== 'deactivated');
      return {
        ...state,
        users: activeUsers,
        deactivatedUsers,
      };
    },
    [rejectRequestedAccess.fulfilled]: (state, { payload }) => {
      const updatedUsers = state.suggestedUsers.filter((user) => user.id !== payload.id);
      return {
        ...state,
        suggestedUsers: updatedUsers,
      };
    },
    [bulkRemoveSuggestedUsers.fulfilled]: (state, { payload }) => {
      const updatedUsers = state.suggestedUsers.filter((user) => !payload.includes(user.id));
      return {
        ...state,
        suggestedUsers: updatedUsers,
      };
    },
    [inviteUser.fulfilled]: (state, { payload }) => ({
      ...state,
      users: [...state.users, payload],
      licenses: serializeLicenses(payload.spiroSubscription.licenses),
    }),
    [inviteSuggestedUser.fulfilled]: (state, { payload }) => {
      const suggestedUsers = state.suggestedUsers.filter((user) => user.id !== payload.id);

      return {
        ...state,
        users: [...state.users, payload.user],
        suggestedUsers,
        licenses: serializeLicenses(payload.user.spiroSubscription.licenses),
      };
    },
    [deactivateAndReasignLicense.fulfilled]: (state, { payload }) => {
      const allUsers = [...state.users, ...state.deactivatedUsers];

      const deactivatedIndex = allUsers.findIndex((u) => u.id === payload.deactivatedUser.id);
      const userWithNewLicenseIndex = allUsers.findIndex(
        (u) => u.id === payload.userWithNewLicense.id
      );
      const updatedUsers = [...allUsers];

      if (deactivatedIndex !== -1) {
        updatedUsers[deactivatedIndex] = {
          ...updatedUsers[deactivatedIndex],
          ...payload.deactivatedUser,
        };
      }
      if (userWithNewLicenseIndex !== -1) {
        updatedUsers[userWithNewLicenseIndex] = {
          ...updatedUsers[userWithNewLicenseIndex],
          ...payload.userWithNewLicense.payload,
        };
      }
      const deactivatedUsers = updatedUsers.filter((u) => u.status === 'deactivated');
      const activeUsers = updatedUsers.filter((u) => u.status !== 'deactivated');

      return {
        ...state,
        users: activeUsers,
        deactivatedUsers,
      };
    },
  },
});

export const {
  setSelectedTab,
  setSelectedUser,
  setSelectedUserIds,
  updateTwilioData,
  updateLicensesCount,
  setQueryParam,
} = userAdministrationSlice.actions;

export default userAdministrationSlice.reducer;
