import { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import AttachmentContext from './AttachmentContext';

export default function AttachmentContextProvider({ children }) {
  const [label, setLabel] = useState('');
  const [fileUrls, setFileUrls] = useState('');
  const [uploading, setUploading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const contextValue = useMemo(
    () => ({
      label,
      setLabel,
      fileUrls,
      setFileUrls,
      uploading,
      setUploading,
      editMode,
      setEditMode,
    }),
    [label, fileUrls, uploading, editMode]
  );

  return <AttachmentContext.Provider value={contextValue}>{children}</AttachmentContext.Provider>;
}

AttachmentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
