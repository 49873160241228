import { useCallback } from 'react';

const useDrawerNavigation = (drawerBodyRef, onPreviousClick, onNextClick, isFirst, isLast) => {
  const handleScrollToTop = useCallback(
    (callback) => {
      const drawerBody = drawerBodyRef.current;

      if (!drawerBody) {
        callback?.();
      }

      if (drawerBody.scrollTop === 0) {
        callback?.();
      } else {
        drawerBody.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        const onScrollEnd = () => {
          callback?.();
          drawerBody.removeEventListener('scrollend', onScrollEnd);
        };

        drawerBody.addEventListener('scrollend', onScrollEnd);
      }
    },
    [drawerBodyRef]
  );

  const handleOnPreviousClick = () => {
    if (!isFirst) handleScrollToTop(onPreviousClick);
  };

  const handleOnNextClick = () => {
    if (!isLast) handleScrollToTop(onNextClick);
  };

  return {
    handleOnPreviousClick,
    handleOnNextClick,
  };
};

export default useDrawerNavigation;
