import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import SpiroTooltip from '../SpiroTooltip';

import styles from './DrawerNavigation.module.scss';

export default function DrawerNavigation({ drawerNavigationProps = null }) {
  if (!drawerNavigationProps) return null;

  return (
    <div className={styles.container}>
      <SpiroTooltip
        title={drawerNavigationProps.isFirst ? 'Go to previous page' : 'Previous record'}
        placement="top"
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            marginBottom: '5px !important',
          },
        }}
      >
        <span>
          <ChevronLeftIcon
            onClick={drawerNavigationProps.onPreviousClick}
            className={drawerNavigationProps.isFirst ? styles.disabled : ''}
          />
        </span>
      </SpiroTooltip>
      <SpiroTooltip
        title={drawerNavigationProps.isLast ? 'Go to next page' : 'Next record'}
        placement="top"
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            marginBottom: '5px !important',
          },
        }}
      >
        <span>
          <ChevronRightIcon
            onClick={drawerNavigationProps.onNextClick}
            className={drawerNavigationProps.isLast ? styles.disabled : ''}
          />
        </span>
      </SpiroTooltip>
    </div>
  );
}

DrawerNavigation.propTypes = {
  drawerNavigationProps: PropTypes.object,
};
