import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import InCallContactDetails from 'components/CallDialog/InCallContactDetails';
import { selectTwilioConnection } from 'components/CallDialog/state/selectors';
import { transferCall } from 'services/twilioService';
import { success as successAlert } from 'state/notifications/actions';

import { toggleTransferCall } from '../../state/actions';

import styles from './TransferCallItem.module.scss';

export default function TransferCallItem({ profiles }) {
  const connection = useSelector(selectTwilioConnection);
  const dispatch = useDispatch();

  const handleTransferCall = async (profile) => {
    if (profile.available) {
      await transferCall({
        call: {
          call_sid: connection.parameters.CallSid,
          user_id: profile.value,
        },
      });
      dispatch(toggleTransferCall(false));
      dispatch(successAlert('Call  transfer in progress!'));
    }
  };

  return (
    <>
      {profiles.map((profile) => (
        <Grid
          className={
            profile.available ? styles['profile-available'] : styles['profile-unavailable']
          }
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          key={uuidv4()}
          onClick={() => handleTransferCall(profile)}
        >
          <Grid item>
            <InCallContactDetails
              title={profile.full_name}
              subheader={parsePhoneNumberFromString(profile.number).formatInternational()}
            />
          </Grid>
          <Grid item>
            <IconButton className={styles.icon} size="medium">
              <PhoneForwardedOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

TransferCallItem.propTypes = {
  profiles: PropTypes.array.isRequired,
};
