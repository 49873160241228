import MaterialChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const colors = {
  purple: '#5D43CF',
  lightPurple: 'rgba(174, 161, 231, 0.24)',
  lightPurple2: '#BEB4EC',
  lightGreen: '#EDF7ED',
  green: '#3B873E',
  lightRed: '#FEECEB',
  red: '#E31B0C',
  lightYellow: '#FFF5E5',
  yellow: '#C77700',
  lightGray: 'rgba(128, 128, 128, 0.08)',
  black: '#000000',
  gray: '#999999',
};

const StyledChip = styled(MaterialChip)(({ label, hoverable, noMargin, marginBottom }) => {
  if (/^\d+\/\d+ Active$/.test(label)) {
    return {
      color: colors.green,
      backgroundColor: colors.lightGreen,
      border: 0,
      fontSize: '14px',
      padding: '0px 4px',
    };
  }

  const colorStyles = (labelName) => {
    switch (labelName) {
      case 'Active':
      case 'Low':
      case 'Baja':
      case 'Uncontacted':
      case 'Deactivated':
        return { color: colors.black, backgroundColor: colors.lightGray, border: 0 };
      case 'Pending':
      case 'Medium':
      case 'Media':
        return { color: colors.yellow, backgroundColor: colors.lightYellow, border: 0 };
      case 'Closed':
      case 'Connected':
      case 'Completed':
        return { color: colors.green, backgroundColor: colors.lightGreen, border: 0 };
      case 'Spam':
      case 'High':
      case 'Urgent':
      case 'Alta':
        return { color: colors.red, backgroundColor: colors.lightRed, border: 0 };
      default:
        return {
          color: colors.black,
          backgroundColor: colors.lightGray,
          border: '1px solid #e0e0e0',
        };
    }
  };

  const styles = colorStyles(label);

  return {
    maxWidth: 220,
    marginLeft: noMargin ? 0 : 16,
    color: styles.color,
    backgroundColor: styles.backgroundColor,
    border: hoverable && '1px solid transparent',
    marginBottom: marginBottom && '2px',
    '&:hover': {
      backgroundColor: hoverable ? colors.lightPurple : undefined,
      border: hoverable ? '1px solid #5D43CF' : undefined,
      color: hoverable ? colors.purple : undefined,
      '& .MuiSvgIcon-root': {
        color: hoverable ? colors.purple : undefined,
      },
    },
  };
});

function Chip(props) {
  const {
    label,
    hoverable = false,
    noMargin = false,
    onDelete = null,
    onClick = null,
    dataCy = 'chip',
    marginBottom = false,
    icon = null,
    sx = null,
    disabled = false,
  } = props;

  if (label.length >= 29)
    return (
      <Tooltip title={label} arrow>
        <div>
          <StyledChip
            label={label}
            hoverable={hoverable}
            noMargin={noMargin}
            marginBottom={marginBottom}
            onDelete={onDelete}
            onClick={onClick}
            data-cy={dataCy}
            icon={icon}
            sx={sx}
            disabled={disabled}
          />
        </div>
      </Tooltip>
    );

  return (
    <StyledChip
      label={label}
      hoverable={hoverable}
      noMargin={noMargin}
      marginBottom={marginBottom}
      onDelete={onDelete}
      onClick={onClick}
      data-cy={dataCy}
      icon={icon}
      sx={sx}
      disabled={disabled}
    />
  );
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  hoverable: PropTypes.bool,
  noMargin: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  dataCy: PropTypes.string,
  marginBottom: PropTypes.bool,
  icon: PropTypes.node,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Chip;
