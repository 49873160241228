import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  visible: false,
  props: null,
};

export const userAdministrationSlice = createSlice({
  name: 'user-administration',
  initialState,
  reducers: {
    openUserAdminModal: (state, { payload }) => ({
      ...state,
      visible: true,
      name: payload.name,
      props: payload.props,
    }),
    closeUserAdminModal: () => ({ ...initialState }),
  },
});

export const { openUserAdminModal, closeUserAdminModal } = userAdministrationSlice.actions;

export default userAdministrationSlice.reducer;
