/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';

import PropTypes from 'prop-types';

import styles from './Autocomplete.module.scss';

const ListBox = forwardRef(({ children, checkboxOption = null, ...rest }, ref) => (
  <div>
    {checkboxOption && <div className={styles['checkbox-container']}>{checkboxOption}</div>}
    <ul {...rest} ref={ref} role="listbox" style={{ paddingTop: 0 }}>
      {children}
    </ul>
  </div>
));

ListBox.propTypes = {
  checkboxOption: PropTypes.node,
  children: PropTypes.node.isRequired,
};

ListBox.displayName = 'ListBox';

export default ListBox;
