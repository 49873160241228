import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

import { Tabs } from 'components/DetailsDrawer';
import Tab from 'lib/Tab';

import ChipList from './ChipList';
import useNotificationsFilters from './useNotificationsFilters';
import UnreadNotificationsToggle from '../UnreadNotificationsToggle';

import styles from './NotificationsFilters.module.scss';

function NotificationsFilters({ fetchNotifications, isLoading, clearState, handleNavigate }) {
  const {
    selectedTab,
    priorityChips,
    generalChips,
    selectTab,
    toggleChipState,
    setPriorityChips,
    setGeneralChips,
    priorityLabel,
    generalLabel,
  } = useNotificationsFilters(fetchNotifications, clearState);

  return (
    <div className={styles.wrapper}>
      <Tabs value={selectedTab} onChange={selectTab}>
        <Tab label={priorityLabel} uppercaseText />
        <Tab label={generalLabel} uppercaseText />
      </Tabs>
      {selectedTab === 0 && (
        <ChipList
          chips={priorityChips}
          onChipClick={toggleChipState(setPriorityChips)}
          isLoading={isLoading}
        />
      )}
      {selectedTab === 1 && (
        <ChipList
          chips={generalChips}
          onChipClick={toggleChipState(setGeneralChips)}
          isLoading={isLoading}
        />
      )}
      <div className={styles.actions}>
        <UnreadNotificationsToggle
          fetchNotifications={fetchNotifications}
          isLoading={isLoading}
          clearState={clearState}
        />
        <IconButton className={styles.button} onClick={handleNavigate}>
          <SettingsOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
}

NotificationsFilters.propTypes = {
  fetchNotifications: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  handleNavigate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NotificationsFilters;
