import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useConnectedEmail from 'hooks/useConnectedEmail';
import useHotjar from 'hooks/useHotjar';
import { hideModal, showEmailModal, showModal } from 'modals/state/actions';
import { selectUser } from 'state/user/selectors';

import { ReactComponent as EmailDisconnected } from '../../icons/email-disconnected.svg';

function EmailIndicator() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { logCustomEvent } = useHotjar();

  const dispatch = useDispatch();

  const openSendEmailModal = () => {
    dispatch(showEmailModal({ show: true }));
    logCustomEvent('email_modal_opened_from_top_menu_bar');
  };

  const openConnectEmailModal = () => {
    dispatch(
      showModal('CONFIRMATION_MODAL', {
        title: `Your email is not connected`,
        message: `Connect your email account.`,
        visible: true,
        confirmButtonText: 'Connect Email',
        onConfirmClick: () => {
          navigate('/settings/my-connections');
          dispatch(hideModal());
        },
        onCancelClick: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const [connectedEmail] = useConnectedEmail();

  return connectedEmail || user.emailConnected ? (
    <IconButton aria-label="Email" color="inherit" onClick={openSendEmailModal} size="large">
      <EmailOutlinedIcon />
    </IconButton>
  ) : (
    <IconButton
      aria-label="Disconnected Email"
      color="inherit"
      onClick={openConnectEmailModal}
      size="large"
    >
      <EmailDisconnected />
    </IconButton>
  );
}

export default EmailIndicator;
