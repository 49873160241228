import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'helpers/meta';

import { fetchQuotes, updateQuote } from './actions';
import { immutableSplice } from '../../../../../helpers/immutableHelper';

const initialState = {
  fetching: true,
  data: [],
  isModalVisible: false,
  meta: nullMeta,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    query: undefined,
  },
  recordsCount: 0,
  newQuoteCreated: false,
  quoteProductsAdded: false,
};

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    setNewQuoteModalVisible: (state, { payload }) => ({ ...state, isModalVisible: payload }),
    setQuotesCount: (state, { payload }) => ({ ...state, recordsCount: payload }),
    replaceQuoteInList: (state, { payload }) => {
      const index = state.data.findIndex((q) => q.id === payload.id);
      return { ...state, data: immutableSplice(state.data, index, 1, payload) };
    },
    setNewQuoteCreated: (state, { payload }) => ({ ...state, newQuoteCreated: payload }),
    setQuoteProductsAdded: (state, { payload }) => ({ ...state, quoteProductsAdded: payload }),
  },
  extraReducers: {
    [fetchQuotes.pending]: (state) => ({ ...state, fetching: true }),
    [fetchQuotes.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.quotes,
      meta: parseMetaFromResponse(payload.meta),
      params: payload.params,
      fetching: false,
    }),
    [fetchQuotes.rejected]: (state) => ({ ...state, fetching: false }),
    [updateQuote.fulfilled]: (state, { payload }) => ({
      ...state,
      data: state.data.map((quote) => {
        if (quote.id !== payload.id) return quote;
        return payload;
      }),
    }),
  },
});

export const {
  setNewQuoteModalVisible,
  setQuotesCount,
  replaceQuoteInList,
  setNewQuoteCreated,
  setQuoteProductsAdded,
} = quotesSlice.actions;

export default quotesSlice.reducer;
