import { combineReducers } from '@reduxjs/toolkit';

import activityTypesForm from './activity-types-form/reducers';
import addAttachment from './add-attachment/reducers';
import emailConnectExchange from './email-connect-exchange/reducers';
import emailConnectImap from './email-connect-imap/reducers';
import pipeline from './pipeline/reducers';
import userAdministration from './user-administration/reducers';
import userPassword from './user-password-modal/reducers';

export default combineReducers({
  addAttachment,
  userPassword,
  pipeline,
  activityTypesForm,
  emailConnectExchange,
  emailConnectImap,
  userAdministration,
});
