import { parseMetaFromResponse } from 'helpers/meta';
import { getAccountFilterProperties } from 'services/accounts';
import { getContactFilterProperties } from 'services/contacts';
import { getCustomObjectsColumns } from 'services/custom-objects';
import { getOpportunityFilterProperties } from 'services/opportunities';
import { getOrderFilterProperties } from 'services/orders';
import { getProductFilterProperties } from 'services/products';
import { getQuoteFilterProperties } from 'services/quotes';
import { getRemindersFilterProperties } from 'services/reminders';
import {
  create as createSavedFilter,
  del as deleteSavedFilter,
  getAll as getAllSavedFilters,
  update as updateSavedFilter,
} from 'services/saved-filters';
import { getCampaingFilterProperties } from 'services/targets';
import { getTicketsFilterProperties } from 'services/ticket';
import { success as successAlert } from 'state/notifications/actions';

import {
  ADD_SAVED_FILTER,
  REMOVE_SAVED_FILTER,
  RESET_CURRENT_FILTERS,
  RESET_FILTER_PROPERTIES,
  RESET_FILTERS_STATE,
  SET_CURRENT_FILTERS,
  STORE_FILTER_PROPERTIES,
  STORE_SAVED_FILTERS,
  UPDATE_SAVED_FILTER,
} from './constants';

const SINGULARS = {
  Contact: 'contact',
  Opportunity: 'opportunity',
  Ticket: 'ticket',
  Account: 'account',
  Reminder: 'reminder',
  Quote: 'quote',
  Custom: 'custom_field',
  SalesOrders: 'sales_order',
  Campaign: 'list',
  Product: 'product',
};

const getFiltersDataFunction = {
  Account: getAccountFilterProperties,
  Contact: getContactFilterProperties,
  Opportunity: getOpportunityFilterProperties,
  Ticket: getTicketsFilterProperties,
  Reminder: getRemindersFilterProperties,
  Quote: getQuoteFilterProperties,
  Custom: getCustomObjectsColumns,
  SalesOrders: getOrderFilterProperties,
  Campaign: getCampaingFilterProperties,
  Product: getProductFilterProperties,
};

function storeFilterProperties(data) {
  return {
    type: STORE_FILTER_PROPERTIES,
    data,
  };
}

export function fetchFilterProperties(resource) {
  return async (dispatch, getState) => {
    const isCached = !!getState().filters.nestedFilters.filterProperties.length;

    if (isCached) return Promise.resolve();

    const json = await getFiltersDataFunction[resource]();

    dispatch(storeFilterProperties(json));
    return Promise.resolve();
  };
}

function storeSavedFilters(json) {
  return {
    type: STORE_SAVED_FILTERS,
    data: json.saved_filters,
    meta: parseMetaFromResponse(json.meta),
  };
}

export function fetchSavedFilters(resource) {
  return async (dispatch, getState) => {
    const isCached = getState().filters.nestedFilters.savedFiltersMeta.currentPage;

    if (isCached) return Promise.resolve();

    const json = await getAllSavedFilters({ per_page: 50, resource: SINGULARS[resource] });

    dispatch(storeSavedFilters(json));
    return Promise.resolve();
  };
}

export function setCurrentFilters(data) {
  return {
    type: SET_CURRENT_FILTERS,
    data,
  };
}

export function resetCurrentFilters(data) {
  return {
    type: RESET_CURRENT_FILTERS,
    data,
  };
}

export function addSavedFilter(data) {
  return {
    type: ADD_SAVED_FILTER,
    data,
  };
}

export function saveFilter(data, resource) {
  return (dispatch) =>
    createSavedFilter({
      resource: SINGULARS[resource],
      ...data,
    })
      .then((response) => {
        dispatch(addSavedFilter(response));
        dispatch(successAlert('Filter is saved.'));
        return Promise.resolve(response);
      })
      .catch((e) => Promise.reject(e));
}

export function removeSavedFilter(id) {
  return {
    type: REMOVE_SAVED_FILTER,
    id,
  };
}

export function deleteFilter(id) {
  return (dispatch) =>
    deleteSavedFilter(id)
      .then(() => {
        dispatch(removeSavedFilter(id));
        return Promise.resolve();
      })
      .catch((e) => Promise.reject(e));
}

export function updateFilter(data) {
  return {
    type: UPDATE_SAVED_FILTER,
    data,
  };
}

export function editFilter(id, data) {
  return (dispatch) =>
    updateSavedFilter(id, data)
      .then((updatedFilter) => {
        dispatch(updateFilter(updatedFilter));
        return Promise.resolve(updatedFilter);
      })
      .catch((e) => Promise.reject(e));
}

export function resetNestedFiltersState() {
  return {
    type: RESET_FILTERS_STATE,
  };
}

export function clearFilterProperties() {
  return {
    type: RESET_FILTER_PROPERTIES,
  };
}
