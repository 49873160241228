import { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/Button';

import styles from './MoreButton.module.scss';

function MoreButton({
  menuItems = [],
  children = '',
  orangeVariant = false,
  narrowerVariant = false,
  callbackFn = () => {},
  endIcon = <KeyboardArrowDownIcon data-cy="more-dropdown-button-arrow-down-icon" />,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    callbackFn();
  };

  const handleOnItemClick = (handleClick, preventCloseMenu = false) => {
    handleClick();
    if (!preventCloseMenu) handleCloseMenu();
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        {...props}
        aria-controls="moreBtn-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        endIcon={endIcon}
        className={orangeVariant ? styles['orange-button'] : ''}
        data-cy="more-dropdown-button"
      >
        {children || t('localization.navigation.more')}
      </Button>
      <Menu
        elevation={0}
        PaperProps={{
          style: {
            background: '#ffffff',
            boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.08)',
            borderRadius: '8px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id="moreBtn-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {menuItems.map(
          (item) =>
            item.id && (
              <Box key={item.id}>
                <MenuItem
                  disabled={item.disabled}
                  className={narrowerVariant ? styles['menu-item-narrower'] : styles['menu-item']}
                  onClick={() => handleOnItemClick(item.onClick, item?.preventCloseMenu)}
                  data-cy={`more-dropdown-btn-item-${item.id}`}
                >
                  {item.icon && (
                    <ListItemIcon className={styles['icon-wrapper']}>{item.icon}</ListItemIcon>
                  )}
                  <Typography variant="body1">{item.actionTitle}</Typography>
                  {item.endIcon && (
                    <ListItemIcon className={styles['end-icon-wrapper']}>
                      {item.endIcon}
                    </ListItemIcon>
                  )}
                </MenuItem>
                {item.divider ? <Divider /> : null}
              </Box>
            )
        )}
      </Menu>
    </div>
  );
}

MoreButton.propTypes = {
  menuItems: PropTypes.array,
  children: PropTypes.string,
  orangeVariant: PropTypes.bool,
  endIcon: PropTypes.node,
  narrowerVariant: PropTypes.bool,
  preventCloseMenu: PropTypes.bool,
  callbackFn: PropTypes.func,
};

export default MoreButton;
