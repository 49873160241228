import EngineService from '../core/engine-service';

const ENDPOINT = '/assistant_rule_folders';

export function getAssistantRulesFolders(params = {}) {
  return EngineService.get(`${ENDPOINT}`, params).then((json) => json);
}

export function createAssistantRulesFolder(payload) {
  return EngineService.post(ENDPOINT, { assistant_rule_folder: payload }).then((json) => json);
}

export function updateAssistantRulesFolder(folderId, payload) {
  return EngineService.put(`${ENDPOINT}/${folderId}`, { assistant_rule_folder: payload }).then(
    (json) => json
  );
}

export function deleteAssistantRulesFolder(folderId) {
  return EngineService.delete(`${ENDPOINT}/${folderId}`).then((json) => json);
}

export function getAssistantFoldersFilterProperties(params = {}) {
  return EngineService.get('/filters/assistant_rules/columns', params).then((json) => json);
}
