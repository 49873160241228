export function getVisibilityValue(value) {
  return (
    {
      Everyone: 1,
      'User Only': 2,
      'User and Manager': 3,
      'User Group and Manager': 4,
    }[value] || ''
  );
}

export const roles = [
  {
    value: 'normal',
    label: 'Sales Rep',
  },
  {
    value: 'basic',
    label: 'Sales Rep (limited)',
  },
  {
    value: 'manager',
    label: 'Manager',
  },
  {
    value: 'basic_manager',
    label: 'Manager (limited)',
  },
  {
    value: 'supermanager',
    label: 'Executive (all data)',
  },
  {
    value: 'partner',
    label: 'Partner',
  },
];

export const adminDropdownOptions = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
];

export const emailVisibilityOptions = [
  { label: 'User only', value: 'me_only' },
  { label: 'User and Manager', value: 'managers_only' },
  { label: 'User Group and Manager', value: 'group_only' },
  { label: 'Everyone', value: 'everyone' },
];

export const licensesTypesOptions = [
  { value: 'full_license', label: 'Full License' },
  { value: 'system_license', label: 'System License', disabled: true },
  { value: 'read_only_license', label: 'Read Only License' },
  { value: 'email_sync_license', label: 'Email Sync License' },
  { value: 'phone_license', label: 'Phone License' },
  { value: 'notetaker_license', label: 'Notetaker License' },
];

export function getLicenseTypeValue(value) {
  return (
    {
      'Full Licenses': 'full_license',
      'Read Only Licenses': 'read_only_license',
      'Email Sync Licenses': 'email_sync_license',
      'Phone Licenses': 'phone_license',
      'Notetaker Licenses': 'notetaker_license',
    }[value] || ''
  );
}

export function getLicenseTypeLabel(value) {
  return (
    {
      full_license: 'Full License',
      read_only_license: 'Read Only License',
      email_sync_license: 'Email Sync License',
      phone_license: 'Phone License',
      notetaker_license: 'Notetaker License',
    }[value] || ''
  );
}

export const getLicensePayload = (licenseType) => {
  if (licenseType === 'notetaker_enabled') return { notetakerEnabled: true };
  if (licenseType === 'phone_enabled') return { phoneEnabled: true };
  return { licenseType };
};

export const optionsForLicensesAvailability = [
  { value: 'full_license', label: 'Full Licenses' },
  { value: 'read_only_license', label: 'Read Only Licenses' },
  { value: 'email_sync_license', label: 'Email Sync Licenses' },
  { value: 'notetaker_enabled', label: 'Notetaker Licenses' },
  { value: 'phone_enabled', label: 'Phone Licenses' },
];

export const mergeLicenseValueAndAvailability = (licenses, value) => {
  const allLicenses = [...licenses, ...optionsForLicensesAvailability].reduce((acc, curr) => {
    const key = curr.label || curr.value || curr.id;
    acc[key] = { ...acc[key], ...curr };
    return acc;
  }, {});

  return Object.values(allLicenses).find(
    (license) => license.value === value || license.id === value
  );
};

export const isLicenseUnavailable = (licenses, data) => {
  if (
    data.phone_enabled &&
    mergeLicenseValueAndAvailability(licenses, 'phone_enabled')?.available <= 0
  ) {
    return true;
  }

  if (
    data.notetaker_enabled &&
    mergeLicenseValueAndAvailability(licenses, 'notetaker_enabled')?.available <= 0
  ) {
    return true;
  }
  if (
    data.license_type &&
    mergeLicenseValueAndAvailability(licenses, data.license_type)?.available <= 0
  ) {
    return true;
  }

  return false;
};

export const renameSortBy = (value) => {
  if (value === 'suggested_user_name') return 'name';
  if (value === 'deactivated_user_name') return 'name';
  if (value === 'deactivatedAt') return 'deactivated_at';
  if (value === 'licenseType') return 'license_type';
  return value;
};

export function serializeFieldsForBulkActions(value) {
  return (
    {
      group_id: 'groupId',
      parent_id: 'managerFullName',
      email_visibility: 'emailVisibility',
      voip_popup_disabled: 'voipPopupDisabled',
    }[value] || value
  );
}

export const serializeLicenses = (licenses) =>
  Object.entries(licenses).map(([name, { total, available }]) => ({
    id: name,
    name,
    singular: name.slice(0, -1),
    total,
    available,
  }));

export const orderMap = {
  1: ['inviteUser', 'cancelInvitation'],
  2: ['reactivate'],
};

export const voipPopupOptions = [
  { value: '0', label: 'Yes' },
  { value: '1', label: 'No' },
];

export const serializeValue = (payload, fullName = '') => {
  if (payload.type === 'parent_id') return fullName;
  if (payload.type === 'voip_popup_disabled') return payload.data[payload.type] !== '0';
  return payload.data[payload.type];
};

export const mainLicenses = ['full_license', 'read_only_license', 'email_sync_license'];
