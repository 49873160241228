import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';

import { removeNamePrefix } from 'helpers/display-fields';

import { resourceTypes } from './utils';

export const defaultResourceTypes = [
  resourceTypes.OPPORTUNITY,
  resourceTypes.CONTACT,
  resourceTypes.ACCOUNT,
  resourceTypes.ACTIVITY,
  resourceTypes.NOTIFICATION,
  resourceTypes.INTERACTION,
  resourceTypes.REMINDER,
  resourceTypes.USER,
];

export const customObjectTypes = {
  LIST: 'list',
  SECTION: 'section',
};

const customFieldTypes = {
  String: 'Short Text',
  Text: 'Long Text',
  Float: 'Number',
  Date: 'Date',
  List: 'Single Picklist',
  MultipleSelect: 'Multi Picklist',
  Currency: 'Currency',
  Link: 'Link',
  Calculated: 'Calculated',
  AccountLookup: 'Company Picker',
  ContactLookup: 'Contact Picker',
  UserLookup: 'User Picker',
  Phone: 'Phone',
  Email: 'Email',
  CustomObjectLookup: 'Entity Picker',
  OpportunityLookup: 'Opportunity Picker',
  SalesOrderLookup: 'Sales Order Picker',
  Attachment: 'Attachment',
  Integer: 'Number',
  CountryPicker: 'Country',
  CurrencyPicker: 'Currency',
  SupportDeskLookup: 'Support Desk',
  DateTime: 'Date & Time',
};

export function getCustomFieldTypeLabel(type) {
  return customFieldTypes[type] || '';
}

export function revertCustomFieldTypeLabel(type) {
  return type[customFieldTypes] || '';
}

export function resolveCustomObjectType(typeString) {
  const id = customObjectTypes[typeString.toUpperCase()];
  return id !== undefined ? id : typeString;
}

function mapActivities(values) {
  return values.map((value) => ({
    body: value.body || '',
    callStatus: value.call_status || 0,
    label: value.label || '',
    order: value.order || 0,
    subject: value.subject || '',
    value: value.value || '',
    feedbacks: value.feedbacks || undefined,
  }));
}

export function customFieldsToArray(customFields = {}) {
  if (customFields && isObject(customFields)) {
    return Object.entries(customFields).map((f) => ({
      name: f[0],
      ...f[1],
    }));
  }
  return [];
}

export function parseActivityCustomFields(data) {
  const values = get(data, 'disposition.value');

  if (!values) {
    return [];
  }

  return mapActivities(values);
}

export function mapCustomFieldsForGrid(customFields, cb) {
  return Object.assign(
    {},
    ...Object.keys(customFields).map((key) => ({
      [`custom_${key}`]: {
        label: customFields[key].label,
        component: cb(key),
      },
    }))
  );
}

export function rejectKeys(customFields, keys) {
  return pickBy(customFields, (value, key) => !keys.includes(key));
}

export function filterOnlyCustomResourceTypes(customFields) {
  const nonEmptyCustomFields = pickBy(customFields, (cf) => !isEmpty(cf));
  return rejectKeys(nonEmptyCustomFields, defaultResourceTypes);
}

export function mapToSelectList(customFields) {
  return Object.keys(customFields)
    .sort()
    .map((key) => {
      const transformedLabels = {
        Account: 'Company',
        Interaction: 'Activity',
      };

      return {
        value: key,
        label: transformedLabels[key] || key,
      };
    });
}

export function isCustomEntity(entity) {
  const defaultEntities = ['Account', 'Contact', 'Opportunity'];
  return !defaultEntities.includes(entity);
}

export function serializeFormData(data) {
  const payload = {
    resource: data.resource,
    visibleOnLists: data.visibleOnLists,
    visibleOnDataCollector: data.visibleOnDataCollector,
    demo: data.demo,
    visibleOnMobile: data.visibleOnMobile,
  };

  if (data.resourceFor) {
    payload.resourceFor = data.resourceFor;
  }

  if (data.options) {
    payload.options = data.options;
  }

  if (data.renderOnField && data.renderOnValue) {
    payload.options = {
      render_on: {
        [data.renderOnField]: data.renderOnValue,
      },
    };
  }

  if (data.schemaType) {
    payload.schemaType = data.schemaType;
  }

  if (data.customEntityId) {
    payload.customEntityId = data.customEntityId;
    delete payload.resourceFor;
  }

  return payload;
}

export function isFieldWithOptions(fieldType) {
  return fieldType === 'List' || fieldType === 'MultipleSelect';
}

export function isStringField(fieldType) {
  return fieldType === 'String' || fieldType === 'Text';
}

export const isCalculatedField = (fieldType) => fieldType === 'Calculated';

function extendOpportunityFields(customFields, fieldObj) {
  if (Object.keys(customFields).length === 0) return customFields;
  const fields = {
    ...customFields.Opportunity.fields,
    ...fieldObj,
  };
  return {
    ...customFields,
    Opportunity: {
      ...customFields.Opportunity,
      fields,
    },
  };
}

export function extendCustomFieldsWithSalesStage(customFields, salesStages, pipelines) {
  return extendOpportunityFields(customFields, {
    opportunity_stage_id: {
      label: 'Sales Stage',
      type: 'List',
      value: salesStages.map((stage) => {
        const pipeline = pipelines && pipelines.find((p) => p.id === stage.pipelineId);

        if (pipeline) {
          return { label: `${stage.name} (${pipeline.name})`, value: `${stage.id}` };
        }

        return { label: `${stage.name}`, value: `${stage.id}` };
      }),
    },
  });
}

export function extendCustomFieldsWithPipelines(customFields, _pipelines) {
  const pipelines = _pipelines.filter((p) => p.isActive);
  return extendOpportunityFields(customFields, {
    pipeline_id: {
      label: 'Pipeline',
      type: 'List',
      value: pipelines.map((s) => ({ label: s.name, value: `${s.id}` })),
    },
  });
}

export function getDefaultValues(customFields, renderFromDisplayFields) {
  const cs = customFields instanceof Array ? keyBy(customFields, 'name') : customFields;
  const defaultValues = omitBy(mapValues(cs, 'default'), isUndefined);
  if (renderFromDisplayFields) {
    Object.keys(defaultValues).forEach((key) => {
      defaultValues[`custom__${key}`] = defaultValues[key];
      delete defaultValues[key];
    });
  }
  return defaultValues;
}

export function getStringCustomFields(customFields) {
  return customFields.map((customField) => {
    const keys = Object.keys(customField.fields);
    const fields = keys.reduce((res, key) => {
      if (
        isStringField(customField.fields[key].type) &&
        customField.fields[key].label !== 'Created By'
      ) {
        return [...res, customField.fields[key]];
      }
      return res;
    }, []);
    return {
      value: customField.id,
      label: customField.name,
      fields,
    };
  });
}

// TODO: This should be combined with the function below
export function getCustomFieldIdForCustomObjectLookup(displayField, customFields) {
  if (displayField.type === 'CustomObjectLookup') {
    return customFields[removeNamePrefix(displayField.name)]?.custom_field_id;
  }
  return null;
}

// TODO: This should be combined with the function above
export function getPickerKeyForCustomObjectLookup(displayField, customFields) {
  if (displayField.type === 'CustomObjectLookup') {
    return customFields[removeNamePrefix(displayField.name)]?.picker_key;
  }
  return null;
}

export function getCustomFieldForEntityPicker(displayField, customFields) {
  return (
    customFields[removeNamePrefix(displayField.name)] || { custom_field_id: null, picker_key: '' }
  );
}

export function relabelFields(resource) {
  if (resource === 'Account') return 'Company';
  if (resource === 'List') return 'Campaigns';
  if (resource === 'SalesOrder') return 'Sales Order';
  if (resource === 'SalesOrderItem') return 'Sales Order Item';
  if (resource === 'SalesOrderSummary') return 'Sales Order Summary';
  if (resource === 'QuoteSummary') return 'Quote Summary';
  if (resource === 'Interaction') return 'Activity';

  return resource;
}

export function getSavedFilterIdForCustomObjectLookup(displayField, customFields) {
  return customFields[removeNamePrefix(displayField.name)]?.saved_filter_id || null;
}

export default {
  parseActivityCustomFields,
};
