import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';

import TextField from '../TextField';

export default function CurrencyField({ currency = '$', ...props }) {
  return (
    <TextField
      {...props}
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
      }}
    />
  );
}

CurrencyField.propTypes = {
  currency: PropTypes.string,
};
