import {
  HIDE_EMAIL_MODAL_DIALOG,
  HIDE_MODAL_DIALOG,
  MINIMIZE_EMAIL_DIALOG,
  SHOW_EMAIL_MODAL_DIALOG,
  SHOW_MODAL_DIALOG,
} from './constants';

const initialState = {
  name: '',
  visible: '',
  props: null,
  isEmailModalMinimized: false,
  isEmailModalOpen: false,
  emailModalProps: null,
};

function showModalHandler(state, action) {
  return {
    ...state,
    name: action.name,
    visible: true,
    props: action.props,
  };
}

function hideModalHandler(state) {
  return { ...state, name: '', visible: '', props: null };
}

function toggleMinimizeEmailModal(state) {
  return { ...state, isEmailModalMinimized: !state.isEmailModalMinimized };
}

function openEmailModal(state, action) {
  return { ...state, isEmailModalOpen: true, emailModalProps: action.props };
}

function hideEmailModal(state) {
  return { ...state, isEmailModalOpen: false, emailModalProps: null, isEmailModalMinimized: false };
}
const ACTION_HANDLERS = {
  [SHOW_MODAL_DIALOG]: showModalHandler,
  [HIDE_MODAL_DIALOG]: hideModalHandler,
  [MINIMIZE_EMAIL_DIALOG]: toggleMinimizeEmailModal,
  [SHOW_EMAIL_MODAL_DIALOG]: openEmailModal,
  [HIDE_EMAIL_MODAL_DIALOG]: hideEmailModal,
};

export default function modalDialogsReducer(state, action) {
  const currentState = state ?? initialState;
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(currentState, action) : currentState;
}
