export const FIELD_TYPES = {
  String: 'String',
  Text: 'Text',
  Date: 'Date',
  List: 'List',
  Link: 'Link',
  Phone: 'Phone',
  Float: 'Float',
  Country: 'Country',
  Calculated: 'Calculated',
  Attachment: 'Attachment',
  Currency: 'Currency',
  MultipleSelect: 'MultipleSelect',
  AccountLookup: 'AccountLookup',
  ContactLookup: 'ContactLookup',
  OpportunityLookup: 'OpportunityLookup',
  UserLookup: 'UserLookup',
  CustomObjectLookup: 'CustomObjectLookup',
  TicketLookup: 'TicketLookup',
  SalesOrderLookup: 'SalesOrderLookup',
  DateTime: 'DateTime',
};
