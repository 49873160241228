import { useEffect } from 'react';

import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CallNotes from 'components/CallDialog/CallNotes';
import CallSubject from 'components/CallDialog/CallSubject';
import {
  minimizeCallModal,
  resetTwilioState,
  setActiveCallModalOpen,
  setActiveTab,
  setFormValues,
  setNoteScreenOpen,
  setPhoneDialogOpen,
} from 'components/CallDialog/state/actions';
import {
  selectFormValues,
  selectIsOutgoingCall,
  selectLogActivityAfterCall,
  selectTwilioCaller,
  selectTwilioCallers,
  selectTwilioConnection,
} from 'components/CallDialog/state/selectors';
import { customFieldsToArray } from 'components/CustomFields/helper';
import InputRenderer from 'components/InputRenderer';
import {
  getCustomFieldIdForCustomObjectLookup,
  getPickerKeyForCustomObjectLookup,
} from 'helpers/custom-fields';
import { getCFOptions } from 'helpers/display-fields';
import Button from 'lib/Button';
import Select from 'lib/Select';
import { getInteractionCustomFields } from 'state/custom-fields/selectors';

import useLogActivity from './useLogActivity';
import useCallerInfo from '../CallerInfo/useCallerInfo';

import styles from './LogActivityForm.module.scss';

function LogActivityForm({ show }) {
  const { isConnectionOpen } = useCallerInfo();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logActivity } = useLogActivity();
  const caller = useSelector(selectTwilioCaller);
  const callers = useSelector(selectTwilioCallers);
  const connection = useSelector(selectTwilioConnection);
  const logActivityAfterCall = useSelector(selectLogActivityAfterCall);
  const isOutgoingCall = useSelector(selectIsOutgoingCall);
  const interactionCustomFields = useSelector((state) => getInteractionCustomFields(state));
  const customFieldsArray = customFieldsToArray(interactionCustomFields);
  const formValues = useSelector(selectFormValues);

  const onCustomFieldChange = (event) => {
    const { value, name } = event.target;
    dispatch(
      setFormValues({
        ...formValues,
        custom: {
          ...formValues.custom,
          [name]: value,
        },
      })
    );
  };

  const customFields = customFieldsArray.length
    ? [
        ...customFieldsArray.map((field) => ({
          show: true,
          name: field.name,
          component: (
            <InputRenderer
              field={{
                type: field.type,
                name: field.name,
                label: field.label,
                value: formValues.custom[field.name] || '',
                options: getCFOptions(field, formValues.custom),
                onChange: onCustomFieldChange,
                compactLookup: false,
                customFieldId: getCustomFieldIdForCustomObjectLookup(
                  field,
                  interactionCustomFields
                ),
                pickerKey: getPickerKeyForCustomObjectLookup(field, interactionCustomFields),
              }}
            />
          ),
        })),
      ]
    : [];

  const handleOnChange = (e) => {
    dispatch(
      setFormValues({
        ...formValues,
        disposition: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (logActivityAfterCall && !formValues.disposition && isOutgoingCall) {
      handleOnChange({ target: { value: 'outbound_call' } });
    }
  }, []);

  if (!show) return null;

  const options = [
    {
      label: t('localization.activity.disposition.connected'),
      value: 'outbound_call',
    },
    {
      label: t('localization.activity.disposition.left_voicemail'),
      value: 'left_voicemail',
    },
    {
      label: t('localization.activity.disposition.didnt_connect'),
      value: 'no_connect',
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    if (!logActivityAfterCall) {
      dispatch(setActiveCallModalOpen(false));
    } else {
      logActivity();
    }
  };

  const handleSaveAndClose = async () => {
    await logActivity();
    dispatch(setPhoneDialogOpen(false));
    dispatch(minimizeCallModal(true));
  };

  const endCallWithoutLogActivity = () => {
    dispatch(resetTwilioState());
    dispatch(setActiveTab('Recents'));
    dispatch(setNoteScreenOpen(false));
  };

  const displayTooltipTitle = () => {
    if (!caller && callers.length > 1 && !connection) return 'Please select or add contact!';
    if (!caller && !callers.length && !connection) return 'Please add contact!';
    return '';
  };

  return (
    <form onSubmit={onSubmit}>
      <Box className={caller ? styles['full-height-wrapper'] : styles.wrapper}>
        {!connection && isOutgoingCall && caller && (
          <FormGroup>
            <Select
              name="disposition"
              value={formValues.disposition}
              label="Call Status"
              removable={false}
              options={options}
              native={false}
              onChange={handleOnChange}
            />
          </FormGroup>
        )}
        {!connection && caller && (
          <>
            {customFields.map((f) => (
              <FormGroup key={f.id}>{f.component}</FormGroup>
            ))}
          </>
        )}
        {!connection && caller && (
          <FormGroup>
            <CallSubject />
          </FormGroup>
        )}
        <FormGroup className={styles['note-field']}>
          <CallNotes />
        </FormGroup>
      </Box>
      <Box className={styles.footer}>
        {!caller && !callers.length && !connection && (
          <Button color="primary" onClick={endCallWithoutLogActivity}>
            Don&apos;t save call
          </Button>
        )}
        <Tooltip
          title={displayTooltipTitle()}
          arrow
          classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
        >
          <div>
            <Button
              disabled={
                (!caller && callers.length > 1) ||
                (!caller && !callers.length) ||
                isConnectionOpen()
              }
              className={styles.button}
              type="submit"
              value="Submit"
              color="primary"
            >
              Save
            </Button>
          </div>
        </Tooltip>
        {caller && (
          <Button
            disabled={isConnectionOpen()}
            onClick={handleSaveAndClose}
            color="primary"
            variant="contained"
          >
            Save & Close
          </Button>
        )}
      </Box>
    </form>
  );
}

LogActivityForm.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default LogActivityForm;
